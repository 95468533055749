import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import styles from './privacyList.module.scss';

function PrivacyList() {

  return (
    <>
      <p style={{ marginBottom: '0pt', textAlign: 'justify', lineHeight: '15pt' }}>
        <span
          style={{
            fontFamily: 'Times New Roman',
            fontSize: '10.5pt',
            color: '#808080',
          }}
        >
          &nbsp;
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          This Privacy Policy explains who we are, how we process (i.e. collect, record, organize, structure, store,
          adapt or alter, retrieve, consult, use, disclose by transmission, disseminate or otherwise make available,
          align or combinate, restrict, erase or destruct) personal information about you, and how you can exercise your
          privacy rights. We are BATTLE OF PREDICTIONS TECHNOLOGY INC, a company under laws of the United States of
          America, with registered address at: 2546 e 17th street, 2nd floor, BROOKLYN, NY 11235 (“BATTLE OF
          PREDICTIONS” or “we”), a developer and publisher of games and mobile game applications that are made available
          via app stores including the Apple App Store and Google Play Store. This Privacy Policy applies to personal
          information that we collect through our website, games, mobile applications and other products (“Games”)
          (together “Services”). By using our Services, you agree to be bound by this Privacy Policy and consent to our
          processing of information as specified therein.{' '}
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>1. INFORMATION WE COLLECT FROM YOU</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Most of the information that we collect about you comes directly from you when you visit our website, play our
          Games or interact with third-party ads in our Games or with our ads in other publisher’s Games. In general,
          the information we collect about you relates to the type of device you are using, information that helps us
          identify your device, how you play our Games and may include information that you submit to us when you
          voluntarily contact us. We may also collect information from app store platforms and partners and other third
          parties such as information about your interests and in-app purchases (provided that we never receive your
          payment or credit card information).
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          The information that we may collect about you through the Services broadly falls into the following
          categories:
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>(a) Information that you provide voluntarily</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Certain parts of our Services may ask you to provide personal information voluntarily. For example, we will
          ask for certain information in order for you (i) to register at our Services, such as:
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          in case of registration via Google account: sub (unique user id), given_name, family_name, user photo;
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          in case of registration via Apple account (for IOS devices only) - apple id, first name, last name;{' '}
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          in case of registration via social network account (like Facebook) - unique user id, first name, last name,
          user photo.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>(ii) to submit enquiries to us, such as:</span>
      </p>
      <ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
        <li
          style={{
            marginTop: '14pt',
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            your name, email address or other details, necessary to process your inquiries;
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            any information you submit to our support team via email.
          </span>
        </li>
      </ul>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          The personal information that you are asked to provide and the reasons why you are asked to provide it, we
          will try to explain to you at the point we ask you to provide your personal information.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>(b) Information that we collect automatically</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          When you use our Services, we may also collect certain information automatically from you and/or your device
          which may be considered personal information under applicable data protection laws, such as:
        </span>
      </p>
      <ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
        <li
          style={{
            marginTop: '14pt',
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Data and analytics about your use of our Services (e.g. in-game play progress, games played, session length,
            preferred settings, which in-game adverts you may have viewed, in-game references or just game preferences,
            the means by which you were referred to our Services)
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Your IP address and device identifiers such as your Advertising ID, Device ID numbers (for more see
            Advertising IDs and Device IDs explication below)
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Your device type and the operating system that you use
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Your Service communications (e.g. in-game messages which you exchange with other players)
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Broad geographic location (e.g. country or city-level location) based on your IP address
          </span>
        </li>
      </ul>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>Advertising ID</span>
        </em>
        <span style={{ fontFamily: 'Times New Roman' }}>
          {' '}
          means Apple Identifier for Advertisers (“IDFA”) on Apple and Google Advertising Identity (“GAID”). These are
          unique identifiers for mobile device that advertisers use for personalized advertising. They are consistent
          across all mobile applications and thus allow cross-app tracking. On Apple, you can opt out of it by choosing
          “limit ad tracking” option in your privacy settings. On Google Android, you can opt out of these ads by
          choosing “Opt-out of personalized ads”. Advertising IDs are non-permanent, non-personal identifiers, which are
          uniquely associated with your device.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>Device identifier / information</span>
        </em>
        <span style={{ fontFamily: 'Times New Roman' }}>
          {' '}
          means device type, device model, device maker, device operating system and its language or other technical
          data like screen size or processor, or combination of this data available from a device for a developer for
          checking the device compatibility with a particular application.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>(c)</span>
        <span style={{ fontFamily: 'Times New Roman' }}>&nbsp;</span>
        <span style={{ fontFamily: 'Times New Roman' }}>Information we obtain from third-party sources</span>
      </p>
      <ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
        <li
          style={{
            marginTop: '14pt',
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>Third-Party Social Network/s.</span>
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>&nbsp;</span>
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Google Play Games Service, Apple Game Center, other social networks like Facebook (“Third-Party Social
            Network/s”). You may choose to connect to our Services via your social media account. Exactly what
            information we receive from your social media will depend on your social media privacy settings, but it
            would typically include your basic public profile information such as your username, gender, your friends
            playing the same Game. (See below also “Third-Party Social Networks”).
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Third party service providers. We may receive personal information about you from third-party sources. We
            may get your personal information for example when you installed our Game and prior to installing you have
            clicked an advertisement for the same Game.
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Advertising partners. From time to time, we may also receive personal information about you from other
            third-party sources. For example, if you clicked on an advertisement to direct you to one of our Services
            (such as an advert of one of our Games in other publisher’s game), we will be provided with information from
            which ad network and advertising campaign the install originated from (See below also “Advertising”).
          </span>
        </li>
      </ul>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          A list of our current service providers and partners is available{' '}
        </span>
        <strong>
          <u>
            <Link className={styles.link} to="/providers" style={{ fontFamily: 'Times New Roman' }}>here</Link>.
          </u>
        </strong>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>(d) No special categories of information</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We do not request or intend to collect any “special categories of information” such as personal data revealing
          racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership,
          genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health
          or data concerning a natural person’s sex life or sexual orientation. Kindly be cautious when sharing this
          information about yourself (or others) in our Services.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>2. HOW WE USE YOUR INFORMATION</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>2.1. In General</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We use the information we collect about you/your device to deliver services and our Games to you and to
          operate our business. We use it also for improving our services and Games, for enhancing security and for
          analytics and research purposes to make sure we provide you with the best experience. In addition, we use your
          information to promote our services and Games in our Games and also in other publisher’s apps and to display
          third-party advertisements to you. We use your information also for tracking and fraud prevention for
          advertising purposes, for complying with our legal obligations and for our legitimate interests.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>We use your information for the following purposes:</span>
      </p>
      <ol style={{ margin: '0pt', paddingLeft: '0pt' }}>
        <li
          style={{
            marginTop: '14pt',
            marginLeft: '54.5pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '4pt',
            fontFamily: 'Times New Roman',
            fontSize: '12pt',
          }}
        >
          Delivering and improving our services. We use your information for developing, delivering and improving our
          Games and other products, services, and content, tailoring our products and services, providing Game updates,
          technical troubleshooting, understanding and analyzing trends in connection with usage of the Games, and
          administering the Games.
        </li>
        <li
          style={{
            marginLeft: '54.5pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '4pt',
            fontFamily: 'Times New Roman',
            fontSize: '12pt',
          }}
        >
          Displaying advertisements. We use your information for showing advertisements for our Games in other
          publisher’s apps and for displaying third-party advertisements in our Games. Please see more in Advertising
          Section of this Privacy Policy.
        </li>
        <li
          style={{
            marginLeft: '54.5pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '4pt',
            fontFamily: 'Times New Roman',
            fontSize: '12pt',
          }}
        >
          Tracking and fraud prevention for advertising purposes. We use your information for tracking how our
          advertising campaigns perform and for identifying and preventing fraud for our advertising campaigns.
        </li>
        <li
          style={{
            marginLeft: '54.5pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '4pt',
            fontFamily: 'Times New Roman',
            fontSize: '12pt',
          }}
        >
          Analytics and research. We use your information for understanding and analyzing trends in connection with the
          usage of the Games, gathering demographic information about our user base, data about where the Game was
          downloaded from. We may also create reports and analysis for the purposes of research or business
          intelligence, for example to track potential problems or trends with our Games and services, or to test our
          new game features and content.
        </li>
        <li
          style={{
            marginLeft: '54.5pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '4pt',
            fontFamily: 'Times New Roman',
            fontSize: '12pt',
          }}
        >
          Security. We use your information for enhancing the safety and security of our Games, products and services.
        </li>
        <li
          style={{
            marginLeft: '54.5pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '4pt',
            fontFamily: 'Times New Roman',
            fontSize: '12pt',
          }}
        >
          Customer Support. We use your information for providing customer support to you and to respond to your
          inquiries.
        </li>
        <li
          style={{
            marginLeft: '54.5pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '4pt',
            fontFamily: 'Times New Roman',
            fontSize: '12pt',
          }}
        >
          Our legal obligations. We use your information when we are required to do so by law, for example, we use your
          country or region for tax reporting purposes.
        </li>
      </ol>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>2.2. In-App Purchases</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Our Games enable in-app purchases. We do not process payments for in-app purchases nor have access to your
          credit card information.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Payment transactions for in-app purchases are completed through the app store via your App Store account or
          Google Play account (payment service providers). You are requested to provide your payment details and
          personal information directly to such payment service providers. We are not involved in the collection and
          processing of such information: instead, we will only receive a notification by the relevant payment service
          provider as to whether payment has been successfully completed.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Any post-purchase processes are controlled by the Apple and Google stores and are subject to specific terms of
          such Apple and Google stores. Please review privacy policy and terms of service of your Apple and Google
          stores.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>3. HOW WE SHARE YOUR INFORMATION</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We cannot provide all services necessary for the successful operation of the Games by ourselves. We must,
          therefore, share collected information with third parties for the purposes of developing and delivering our
          services, displaying advertisements, conducting analysis and research and for measuring our and our Partners’
          advertising campaign performance. We also reserve the right to disclose your information (including personally
          identifiable information) when we are legally required to do so, to disclose your information in an anonymous
          and aggregated manner, meaning you could not be personally identified from it.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>We may share such information to:</span>
      </p>
      <ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
        <li
          style={{
            marginTop: '14pt',
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Our analytics providers. We use third-party analytics such as Firebase for the purposes of delivering our
            services, conducting analysis, and research. The device data we share with such providers are encoded. The
            providers may assign a random ID to these data and they are not able to match it back to You. We can
            aggregate the data for benchmarking feature.
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Our cloud service providers. We store information we collect from you on servers provided by our third-party
            cloud service providers such as Google Cloud Platform;
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Our tracking and fraud prevention providers. For some of our Games we use tracking and fraud prevention
            providers for the purposes of measuring performance and optimizing our advertising campaigns, displaying
            advertisements, conducting analysis to improve our services and for fraud prevention purposes. Such
            providers may use APIs, and SDKs in our Games to enable them to collect and analyze user and device-related
            data and information, such as impressions, clicks, installs or other advertising campaign performance
            indicators and post-install in-app events.
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Third-party advertising partners. We have contracted with certain third-party advertising networks that
            assist us in delivering advertising to you that allows us to continue offering the Games for free or at low
            cost.
          </span>
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>&nbsp;</span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Advertising partner’s third-party tracking provider. We allow our advertising partners to track impressions,
            clicks, installs or other advertising campaign performance indicators through their tracking providers for
            the purposes of measuring their advertising campaign performance. They may use their own third-party
            tracking providers.
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Other third-party service providers. For our Games, we use various software to develop such Games. For some
            Games, we partner with third-party development studios to develop such Games.{' '}
          </span>
        </li>
      </ul>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We may contract with additional Partners or change existing Partners in the future to perform any services in
          connection with the Games. These changes shall be updated from time to time or promptly in case of material
          changes in our data processing practices.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          The list of our current service providers and partners as well as more detailed information about processing
          is available{' '}
        </span>
        <strong>
          <u>
            <Link className={styles.link} to="/providers" style={{ fontFamily: 'Times New Roman' }}>
              here
            </Link>
            .
          </u>
        </strong>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We reserve the right to disclose your information (including personally identifiable information) in response
          to law, legal process, litigation, and/or requests from public and governmental authorities within or outside
          your country of residence. We may also disclose information about you if we determine that for purposes of
          national security, law enforcement, or other issues of public importance, the disclosure is necessary or
          appropriate. We may also disclose information about you if we determine that disclosure is reasonably
          necessary to enforce our terms and conditions or to protect our operations, users or user experience.
          Additionally, in the event of a reorganization, merger or sale we may transfer any and all information
          (including personally identifiable information) we collect to the relevant third party.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          For any additional questions about third-party information collection, please refer to{' '}
          <a className={styles.link} href="mailto:support@bofp.co">
            support@bofp.co
          </a>
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>4. ADVERTISING</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>4.1. In General</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We use advertising to fund our Services and to make our games available for free. We offer our Games for free
          or at low cost and in order to do that we need to share information we collect from you with our third-party
          advertising partners. As a safeguard to protect your privacy, we only store personal information temporarily.
          The information collected helps us to improve our website and Games and – at the same time – keep our Games
          free for a wide player base.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          When you use our Games, we rely on our legitimate interest to show advertisements to you. The legal basis to
          show ads is the legitimate interest in accordance with the European data protection requirements under Art. 6
          para. 1 lit. f GDPR.{' '}
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Before sharing any information with our advertising partners for the purposes of{' '}
        </span>
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>personalized advertising</span>
        </em>
        <span style={{ fontFamily: 'Times New Roman' }}>, we will always ask for your </span>
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>consent</span>
        </em>
        <span style={{ fontFamily: 'Times New Roman' }}>
          . The purpose is to improve our Games and provide the player with more relevant ads. We allow our advertising
          partners to show personalized ads to you only if you have pressed ACCEPT in relevant pop-up window and so
          consented to the sharing of your personal information for the purposes of personalized advertising. Our
          advertising partners use different technologies for the purposes of personalized advertising that process your
          personal information in different ways, so please review their data processing practices before you ACCEPT to
          show you personalized ads.
        </span>
        <span style={{ fontFamily: 'Times New Roman' }}>&nbsp;</span>
        <span style={{ fontFamily: 'Times New Roman' }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          The legal basis to show personalized ads is consent in accordance with the European data protection
          requirements under Art. 6 para. 1 lit. a GDPR. In addition, a data processing agreements were concluded with
          external ad technology providers networks in accordance with the requirements of Art. 28 GDPR. We also ensure
          that our external service providers are committed to a high level of data protection.
        </span>
        <span style={{ fontFamily: 'Times New Roman' }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          You have an option to withdraw your consent to sharing your personal information for the purposes of
          personalized advertising at all times. On Apple, you can opt out of it by choosing “limit ad tracking” option
          in your privacy settings. On Google Android, you can opt out of these ads by choosing “Opt-out of personalized
          ads”. When you decide to withdraw your consent or if you do not provide your consent, you may still see the
          same number of ads on your mobile device; however, these ads may be less relevant because they won’t be based
          on topics you like, your interest, behavior, demographics, etc. Our advertising partners may continue to show
          you ads based on the content of the application you are using.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          4.2. In-game advertising (i.e. when you view ads in Our Games)
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          To enable us to show in-game ads, we may send your advertising ID and IP-address to advertisers (or ad
          networks) to enable them to find appropriate ads to serve to you in our games and on third-party properties.
          You can control and limit our use of your advertising ID in your device settings. We do not control which
          specific ads are shown in our games (it is controlled by ad networks), but we do blacklist certain categories
          of ads.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Advertising IDs are used by the ad networks for a number of purposes including:
        </span>
      </p>
      <ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
        <li
          style={{
            marginTop: '14pt',
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            limit the number of times you see the same ad;
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            ensure they are dealing with humans with real devices and not ‘automated’ bots;
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            serve you with advertisements likely to be relevant to you based on your advertising ID engagement with
            other brands and ads, also recorded using your advertising ID and using your general geographic area. Other
            brands buy advertisement placement via an ad network or ad exchange to advertise their products in Our
            Games. The ad network or ad exchange acts as intermediary between the other game companies and Us. In order
            for an ad network to charge the company buying the ad, clicks from the ad and potentially installs of other
            developers’ applications party apps are tracked. Views, clicks, and installs from ads are measured using
            Advertiser ID;
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            keep track what games you have used to market you similar kind of games. IP-address is typically used by
            advertisers to broadly see the general geographic area you are coming from for example country, state, and
            sometimes at city level).
          </span>
        </li>
      </ul>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>4.3. View of Our ads on third-party services</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We also advertise the Games in other apps by asking ad networks to serve them to audiences that will be
          interested in them (determined using Advertising IDs).
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          The Advertising IDs are used by the ad networks for a number of purposes including:
        </span>
      </p>
      <ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
        <li
          style={{
            marginTop: '14pt',
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            measuring ad effectiveness (looking at aggregated views, clicks and installs they generate through their
            advertising in other publishers’ games);
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            informing players, that have not been playing for a while, about new content in the Game. We give a list of
            these users in the form of Advertising IDs to an ad network. Ad network then shows these users ads about the
            new content;
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            not showing ads on a game which a player is already playing. We can send a list of active players in the
            form of Advertising IDs to an ad network. Ad network then excludes these players from seeing the ad;
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            marketing games to a group of potential players.
          </span>
        </li>
      </ul>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>4.4. WHO CAN SHOW PERSONALIZED ADS TO YOU?</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Please see the list of advertising providers that are allowed to show personalized advertising and review
          their data processing practices, including the technologies they use for the purposes of personalized
          advertising{' '}
        </span>
        <strong>
          <u>
            <Link className={styles.link} to="/providers" style={{ fontFamily: 'Times New Roman' }}>here</Link>.
          </u>
        </strong>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>4.5. HOW CAN YOU TURN OFF PERSONALIZED ADS?</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Besides specific instruments available to users via the Game you can use your device settings.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>iOS</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Open the “Settings” application of iOS and select the “Privacy” menu item and then the “Advertising” sub-item.
          If you activate the “Limit ad tracking” option, we can only take limited measures such as identifying unique
          users or combating fraud. In the same menu you can always delete the IDFA (“Reset Ad-ID”), then a new ID is
          created which is not merged with the data collected earlier.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>Android</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Open the “Settings” application and select the “Google” menu item. Depending on your device, this option may
          not be visible on the main menu but you may use the search function at the top of the Settings menu to find
          it. From there, select the “Ads” sub-item and activate the option “Opt out of Ads Personalization” to prevent
          the creation of profiles and the display of personalized advertising. You can delete the advertising ID in the
          same menu at any time (“Reset advertising ID”), then a new ID will be created which will not be merged with
          the previously collected data.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>5. THIRD-PARTY SOCIAL NETWORKS</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>5.1. Connecting to Third-Party Social Networks</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Some of our Games use third-party services such as Facebook (“Third Party Social Network/s”). Such Games
          enable you to log in to your Third-Party Social Network account to connect our App by using the “Log in”
          feature in our App. You may also share the content you create within the App on Third-Party Social Networks.
          These services are not activated automatically, but require explicit authorization by You. Please note, Third
          Party Social Networks are governed by their own privacy policies, terms and end-user agreements and we are not
          responsible for and have no control over, the sharing practices or policies of the third parties that operate
          those sites. For additional questions, contact us at{' '}
          <a className={styles.link} href="mailto:support@bofp.co">
            support@bofp.co
          </a>
          .
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>5.2. Facebook Login</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          In our Games we may offer you the opportunity to use the "Facebook Login" service of Facebook Ireland Limited,
          4 Grand Canal Square, Dublin 2, Ireland. This allows you to save all your game process using your existing
          Facebook account. After you have logged in with your Facebook login data, Facebook will tell you which data
          will be transmitted for authentication. The legal basis for the processing of your data is the execution of
          our contract, art. 6 (1)(b) GDPR. We have concluded an agreement with Facebook on joint responsibility with
          regard to the processing of your data in accordance with art. 26 GDPR, the terms of which you can view{' '}
        </span>
        <a
          href="https://www.facebook.com/legal/controller_addendum"
          target="_blank"
          style={{ textDecoration: 'none' }}
          rel="noreferrer"
        >
          <span style={{ fontFamily: 'Times New Roman', color: '#000000' }}>here</span>
        </a>
        <span style={{ fontFamily: 'Times New Roman' }}>: </span>
        <span style={{ fontFamily: 'Times New Roman' }}>&nbsp;</span>
        <span style={{ fontFamily: 'Times New Roman' }}>https://www.facebook.com/legal/controller_addendum</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          For more information on how Facebook processes personal data, including how to exercise your rights as a data
          subject against Facebook Ireland, please refer to Facebook's data policy at{' '}
        </span>
        <a
          href="https://www.facebook.com/about/privacy"
          target="_blank"
          style={{ textDecoration: 'none' }}
          rel="noreferrer"
        >
          <u>
            <span style={{ fontFamily: 'Times New Roman', color: '#ff313f' }}>
              https://www.facebook.com/about/privacy
            </span>
          </u>
        </a>
        <span style={{ fontFamily: 'Times New Roman' }}>. </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          By the same principle, we can offer you the opportunity to use login service of other social network like
          Facebook to enter the system.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          5.3. Information We May Receive When You Connect to Third-Party Social Networks
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          The information you allow us to access varies by App and is affected by the privacy settings you establish on
          those Third-Party Social Networks. You can control and find out more about these settings from the Third-Party
          Social Network in question. When you use the “Log in” feature, we may access and store some or all of the
          following information, as allowed by you, Third-Party Social Networks and your preferences: (i) your basic
          information from your public profile. By default, this includes certain information about you such as id,
          name, picture, gender, and your location. If you have made more of your information public, more information
          will be available; (ii) login ID and/or player identifier string, your email address connected to your page;
          (iii) list of your friends; (iv) any other information which is detailed and displayed to you in the notice
          which appears during the “Log in with” process. We may share your name and your game data (such as moves, Game
          progress and scores) with respect to the applicable App, with your friends who have also used the “Log in
          with” or “Connect to” feature in connection with such App or with your contacts in applicable Third-Party
          Social Media Network account.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          The App may further ask you to allow us to post on such Third-Party Social Networks on your behalf (e.g.
          status updates). Please keep in mind that we will only do that if specifically allowed by you and we will
          store ID and your full name for the purposes of user identification. Other information will be merely stored
          within the App on your device so that the App will be able to communicate with Third Party Social Network
          directly.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>5.4.</span>
        <span style={{ fontFamily: 'Times New Roman' }}>&nbsp;</span>
        <span style={{ fontFamily: 'Times New Roman' }}>
          How You Share Your Information On Third-Party Social Networks
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          If you use Third-Party Social Networks to participate in game-related activities, such as leaderboards and
          multiplayer games, you share your information with other players and Third-Party Social Networks (for example,
          scores, rankings, and achievements).
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>6. CHILDREN’S PRIVACY</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Our Service does not address anyone under the age of 16 (“Children”). When we collect personal information, we
          do not know the age of our players. We do not knowingly collect personally identifiable information from
          children under 16. If you are a parent or guardian and you are aware that your Children have provided us with
          Personal Information, please contact us. If we become aware that we have collected Personal Information from a
          child under age 16 without verification of parental consent, we will take steps to remove that information
          from our servers.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Under our Terms of Service users of our games must be at least 16 years of age. Although the graphical content
          of the games may be suitable for younger audiences, there are multiple reasons for responsible developers to
          apply a minimum age of 16. Some of these reasons are in-app purchases and privacy.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Please note: When you download our games from e.g. Google Play or Apple App Store you will find published age
          recommendations in the Apps description. However, these age or maturity classifications refer only to the
          content suitability (similar to movie ratings G, PG or PG-13 in the U.S., but related to gameplay, animations,
          etc.) according to the respective rating body responsible for such ratings. As these ratings are done
          independently by a third party without our contribution, they are not necessarily in line with our Terms of
          Service.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          For more info on how these categories are defined, visit support websites for Apple and Google.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>7. YOUR RIGHTS</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          You have certain rights in connection with your personal information and how we handle it. Some of these
          rights may be subject to some exceptions or limitations. You can exercise these rights at any time by
          following the instructions below or sending us relevant requests to{' '}
          <a className={styles.link} href="mailto:support@bofp.co">
            support@bofp.co
          </a>
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>Your rights include:</span>
      </p>
      <ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
        <li
          style={{
            marginTop: '14pt',
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Right to withdraw your consent in accordance with Art. 7 para. 3 GDPR (e.g. you may withdraw your consent to
            show personalized ads to you as described in this Privacy Policy)
          </span>
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>&nbsp;</span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Right to access your data in accordance with Art. 15 GDPR (e.g. you may check what data we store about you,
            for this purpose please contact us via e-mail{' '}
            <a className={styles.link} href="mailto:support@bofp.co">
            support@bofp.co
            </a>
            )
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Right to correct your data in accordance with Art. 16 GDPR
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Right to have your data deleted in accordance with Art. 17 GDPR (e.g. you can contact us
          </span>
          <span style={{ fontFamily: 'Calibri', fontSize: '11pt' }}> </span>
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            via e-mail{' '}
            <a className={styles.link} href="mailto:support@bofp.co">
            support@bofp.co
          </a>{' '}
            if you want us to delete certain data that we have stored about you)
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Right to restrict processing of your personal information in accordance with Art. 18 GDPR (e.g. you can
            contact us
          </span>
          <span style={{ fontFamily: 'Calibri', fontSize: '11pt' }}> </span>
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            via e-mail{' '}
            <a className={styles.link} href="mailto:support@bofp.co">
            support@bofp.co
          </a>{' '}
            if you want to restrict processing). You have the right, under certain circumstances, to restrict the
            processing of your Data. In this case, we will not process your Data for any purpose other than storing it.{' '}
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Right to take your data with you in accordance with Art. 20 GDPR (e.g. you can contact us
          </span>
          <span style={{ fontFamily: 'Calibri', fontSize: '11pt' }}> </span>
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            via e-mail{' '}
            <a className={styles.link} href="mailto:support@bofp.co">
            support@bofp.co
          </a>{' '}
            to receive your data if you want to upload it to another service)
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Right to object how your data is handled in accordance with Art. 21 GDPR (e.g. you can contact us if you do
            not agree with any user analytics procedures as described within this Privacy Policy). You have the right to
            object to the processing of your Data if the processing is carried out on a legal basis other than consent.
            Where Personal Data is processed for a public interest, in the exercise of an official authority vested in
            the BATTLE OF PREDICTIONS or for the purposes of the legitimate interests pursued by us, you may object to
            such processing by providing a ground related to your particular situation to justify the objection. You
            must know that, however, should your Personal Data be processed for direct marketing purposes, you can
            object to that processing at any time without providing any justification. To learn, whether we are
            processing Personal Data for direct marketing purposes, you may refer to the relevant sections of this
            document or contact us at{' '}
            <a className={styles.link} href="mailto:support@bofp.co">
            support@bofp.co
          </a>
            .
          </span>
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>&nbsp;</span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Right to send complaints to the supervisory authority in accordance with Art. 77 para. 1 f GDPR (e.g. you
            can contact the data protection supervisory authority directly).
          </span>
        </li>
      </ul>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>&nbsp;</span>
        <span style={{ fontFamily: 'Times New Roman' }}>8. GENERAL</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Your information privacy and security are important to us and we always pay special attention to ensure that
          we process your information lawfully in accordance with one of the legal bases set out under EEA data
          protection laws that apply to our users. We store your information for as long as needed to provide you with
          our services. We may store information longer, but only in a way that it cannot be tracked back to you. We
          operate globally and we share your information with our Partners (see the List of our current Partners{' '}
        </span>
        <strong>
          <u>
            <Link className={styles.link} to="/providers" style={{ fontFamily: 'Times New Roman' }}>here</Link>
          </u>
        </strong>
        <span style={{ fontFamily: 'Times New Roman' }}>
          ) as for the purposes described above in “How We Share Your Information” Section, which may include a transfer
          of your personal information outside of the European Economic Area (the “EEA”). Please check regularly for
          changes to our Privacy Policy.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>9. LEGAL BASES</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          In each case where we process your information, we do so lawfully in accordance with one of the legal bases
          set out under EEA data protection law.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>The legal bases that we rely upon are the following:</span>
      </p>
      <ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
        <li
          style={{
            marginTop: '14pt',
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Contractual Necessity. This covers information that is processed by us in order to provide you with service
            that you have requested – and that we have agreed – to provide to you, that is to deliver and improve our
            services, to provide customer support, to deliver special features in our Games (such as in-app chat
            feature, multiplayer feature, leaderboards and other third-party social network features), to deliver
            content of the in-app purchase you make.
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Legitimate Interests. This covers information that is processed by us for the purposes that can be
            reasonably expected within the context of your use of our services to pursue our legitimate interests in
            order to ensure you have the best experience when playing our apps, to make sure your information is secure
            and to provide to you our Games free of charge or at a low cost. We pay special attention to your data
            protection rights making sure that your data protection rights are not overridden by our legitimate
            interests. We rely on our legitimate interests for data processing for analytics, tracking and fraud
            prevention, push-notifications, cross-promotion, contextual advertising purposes.
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Consent. Where we ask for your consent to use your data for a particular purpose, we will make this clear at
            the point of collection and we will also make clear how you can withdraw your consent. We will ask for your
            consent before sharing any information with our advertising partners for the purposes of personalized
            advertising and before conducting any surveys. Note: Under some legislations we may be allowed to process
            Personal Data until You object to such processing (“opt-out”), without having to rely on consent or any
            other of the following legal bases. This, however, does not apply, whenever the processing of Personal Data
            is subject to European data protection law.
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            Legal Obligation. This covers information that is processed by us to comply with a legal obligation, for
            instance, is to maintain records for tax purposes.
          </span>
        </li>
      </ul>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          In any case, we will gladly help to clarify the specific legal basis that applies to the processing, and in
          particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement
          necessary to enter into a contract.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>10. SECURITY</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We have implemented appropriate technical and organizational measures to protect the confidentiality, security
          and integrity of the collected information, and to prevent unauthorized access and the use of information
          contrary to this Privacy Policy. Although we use commercially reasonable efforts to assure that your
          information remains secure when maintained by us, please be aware that no security measures are perfect or
          impenetrable.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>11. INTERNATIONAL TRANSFER</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          The information is processed at the BATTLE OF PREDICTIONS’ operating offices and in any other places where the
          parties involved in the processing are located.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Your personal information may be transferred to and processed in countries other than the country in which you
          are resident. These countries may have data protection laws that are different to the laws of your country.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          This means that when we collect your personal information, we may process it in other countries. However, we
          ensure appropriate safeguards are in place so that your personal information will remain protected in
          accordance with this Privacy Policy. These include implementing the European Commission’s Standard Contractual
          Clauses for transfers of personal information between us and third party service providers and partners, which
          require us to protect personal information they process from the EEA in accordance with European Union data
          protection law.{' '}
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>12. RETENTION</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We store your information for as long as needed to provide you with our services. We may store information
          longer, but only in a way that it cannot be tracked back to you. We delete the information as soon as we do
          not need it for the purposes as described in this Privacy Policy and in any event no later than five (5) years
          after your interaction with all of our Games has ceased.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          When required by law, we will retain your information for as long as we are legally obligated to do so. If you
          request us to delete your data we will delete your data as described above in “Your Rights” Section.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Once the retention period expires, Personal Data shall be deleted. Therefore, the right to access, the right
          to erasure, the right to rectification and the right to data portability cannot be enforced after expiration
          of the retention period.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>13. INFORMATION FOR CALIFORNIAN CONSUMERS</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          This part of the document integrates with and supplements the information contained in the rest of the Privacy
          Policy and is provided by us and our subsidiaries and affiliates (for the purposes of this section referred to
          collectively as “we”, “us”, “our”).
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          The provisions contained in this section apply to all users who are consumers residing in the state of
          California, United States of America, according to "The California Consumer Privacy Act of 2018" (Users are
          referred to below, simply as “you”, “your”, “yours”), and, for such consumers, these provisions supersede any
          other possibly divergent or conflicting provisions contained in the Privacy Policy.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          This part of the document uses the term “personal information“ as it is defined in The California Consumer
          Privacy Act (CCPA).
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>
            13.1. Categories of personal information collected, disclosed or sold
          </span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          In this section we summarize the categories of personal information that we collect or disclose and the
          purposes thereof. You can read about these activities in detail in the section 2 titled “How we use your
          information” within this document.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>
            Information we collect: the categories of personal information we collect
          </span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We collect the following categories of personal information about you according to the CCPA: identifiers,
          commercial information, internet information and geolocation data.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We will not collect additional categories of personal information without notifying you.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>
            13.2. How we collect information: what are the sources of the personal information we collect?
          </span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We collect the above mentioned categories of personal information, either directly or indirectly, from you
          when you use our services and Games.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          For example, you directly provide your personal information when you submit requests via any forms on the
          services or Games. You also provide personal information indirectly when you navigate the services and Games,
          as personal information about you is automatically observed and collected. Finally, we may collect your
          personal information from third parties that work with us in connection with the Service or with the
          functioning of the Games and features thereof.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>
            13.3. How we use the information we collect: sharing and disclosing of your personal information with third
            parties for a business purpose
          </span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We may disclose the personal information we collect about you to a third party for business purposes. In this
          case, we enter a written agreement with such third party that requires the recipient to both keep the personal
          information confidential and not use it for any purpose(s) other than those necessary for the performance of
          the agreement.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We may also disclose your personal information to third parties when you explicitly ask or authorize us to do
          so, in order to provide you with our Service.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          To find out more about the purposes of processing, please refer to the relevant section of this document.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>13.4. Sale of your personal information</span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          For our purposes, the word “sale” means any “selling, renting, releasing, disclosing, disseminating, making
          available, transferring or otherwise communicating orally, in writing, or by electronic means, a consumer's
          personal information by the business to another business or a third party, for monetary or other valuable
          consideration”.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          This means that, for example, a sale can happen whenever an application runs ads, or makes statistical
          analyses on the traffic or views, or simply because it uses tools such as social network plugins and the like.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>
            Your right to opt out of the sale of personal information
          </span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          You have the right to opt out of the sale of your personal information. This means that whenever you request
          us to stop selling your data, we will abide by your request.
        </span>
        <br />
        <span style={{ fontFamily: 'Times New Roman' }}>
          Such requests can be made freely, at any time, simply by following the instructions below.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>
            Instructions to opt out of the sale of personal information
          </span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          If you’d like to know more, or exercise your right to opt out in regard to all the sales carried out by this
          Application, both online and offline, you can contact us for further information using the contact details
          provided in this document.
        </span>
        <span style={{ fontFamily: 'Times New Roman' }}>&nbsp;</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>
            13.5. What are the purposes for which we use your personal information?
          </span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We may use your personal information to allow the operational functioning of the services or Games and
          features thereof (“business purposes”). In such cases, your personal information will be processed in a
          fashion necessary and proportionate to the business purpose for which it was collected, and strictly within
          the limits of compatible operational purposes.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We may also use your personal information for other reasons such as for commercial purposes, as well as for
          complying with the law and defending our rights before the competent authorities where our rights and
          interests are threatened or we suffer an actual damage.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We will not use your personal information for different, unrelated, or incompatible purposes without notifying
          you.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>
            13.6. Your California privacy rights and how to exercise them
          </span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>The right to know and to portability</span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          You may submit once a year, free of charge, a verifiable request to disclose what personal information we
          collect about you. The easiest way to submit a request is to use the in-app support сhat in our Games (if
          possible). You may also submit your request via e-mail at{' '}
        </span>
        <u>
          <span style={{ fontFamily: 'Times New Roman', color: '#ff313f' }}>
          <a className={styles.link} href="mailto:support@bofp.co">
            support@bofp.co
          </a>
          </span>
        </u>
        <span style={{ fontFamily: 'Times New Roman' }}>.</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          The disclosure described above will be limited to the personal information collected or used over the past 12
          months.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          If we deliver our response electronically, the information enclosed will be "portable", i.e. delivered in an
          easily usable format to enable you to transmit the information to another entity without hindrance – provided
          that this is technically feasible.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>
            The right to request the deletion of your personal information
          </span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          You have the right to request that we delete any of your personal information, subject to exceptions set forth
          by the law (such as, including but not limited to, where the information is used to identify and repair errors
          on this Application, to detect security incidents and protect against fraudulent or illegal activities, to
          exercise certain rights etc.).
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          If no legal exception applies, as a result of exercising your right, we will delete your personal information
          and direct any of our service providers to do so. However, we may deny your deletion request if retaining the
          information is necessary for us or our service providers under certain circumstances, which will be explained
          to you at the time of the denial, if any. Should you wish any information about you deleted, you may use the
          in-app support chat in our games (if possible) or submit your request via e-mail{' '}
          <a className={styles.link} href="mailto:support@bofp.co">
            support@bofp.co
          </a>
          .
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>Right to opt-out</span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          You have the right to opt-out of “sale” of your personal information, as defined by the CCPA. In certain
          circumstances we may share your information with our partners, who help us deliver advertisements in our games
          tailored to your interests. To learn what information we collect and how we share it with third parties for
          advertising purposes, please, read the relevant section of this Privacy Policy.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          If you would prefer that your personal information is not shared with third parties in this way, please, use
          the in-app support chat in our games (if possible) or submit your request via e-mail{' '}
          <a className={styles.link} href="mailto:support@bofp.co">
            support@bofp.co
          </a>
          .
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>Right to be free from discrimination</span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We may not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we
          will not: (i) deny you our Services; (ii) charge you different prices or rates for our Services; (iii) provide
          you a different level or quality of Services; (iv) suggest that you may receive a different price or rate for
          Services or a different level or quality of Services.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>Authorized agents</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          You may exercise your CCPA rights by designating an authorized agent. If you would like to designate an
          authorized agent to make a request on your behalf, please, be sure that the agent can (i) demonstrate you have
          provided written permission for the agent to submit a request on your behalf and (ii) provide proof of his or
          her own identity. We reserve the right to require further reasonable information to verify the request. If the
          agent does not satisfy these requirements, we may deny the request.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>How to exercise your rights</span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          To exercise the rights described above, you need to submit your verifiable request to us by contacting us via
          the details provided in this document.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          For us to respond to your request, it’s necessary that we know who you are. Therefore, you can only exercise
          the above rights by making a verifiable request which must:
        </span>
      </p>
      <ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
        <li
          style={{
            marginTop: '14pt',
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            provide sufficient information that allows us to reasonably verify you are the person about whom we
            collected personal information or an authorized representative;
          </span>
        </li>
        <li
          style={{
            marginLeft: '27.6pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '8.4pt',
            fontFamily: 'serif',
            fontSize: '10pt',
          }}
        >
          <span style={{ fontFamily: 'Times New Roman', fontSize: '12pt' }}>
            describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to
            it.
          </span>
        </li>
      </ul>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We will not respond to any request if we are unable to verify your identity and therefore confirm the personal
          information in our possession actually relates to you.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          If you are an adult, you can make a verifiable request on behalf of a minor under your parental authority.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          You can submit a maximum number of 1 request over a period of 12 months.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <em>
          <span style={{ fontFamily: 'Times New Roman' }}>How and when we are expected to handle your request</span>
        </em>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We will respond to your request within 45 days of its receipt. Should we need more time, we will explain to
          you the reasons why, and how much more time we need. In this regard, please note that we may take up to 90
          days to fulfill your request. Our disclosure(s) will cover the preceding 12 months period.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          Should we deny your request, we will explain you the reasons behind our denial.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We do not charge a fee to process or respond to your verifiable request unless such request is manifestly
          unfounded or excessive. In such cases, we may charge a reasonable fee, or refuse to act on the request. In
          either case, we will communicate our choices and explain the reasons behind it.
        </span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>14. CHANGES TO OUR PRIVACY POLICY</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          We will occasionally update this Privacy Policy as necessary to protect our users, furnish current
          information, and respond to legal and technical changes. The most current version of the Privacy Policy will
          govern our use of your information and will be available{' '}
        </span>
        <a
          href="https://playgendary.com/en/playgendary-limited-privacy-policy"
          target="_blank"
          style={{ textDecoration: 'none' }}
          rel="noreferrer"
        >
          <span style={{ fontFamily: 'Times New Roman', color: '#000000' }}>at</span>
        </a>
        <span style={{ fontFamily: 'Times New Roman' }}> our site.</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>15. CONTACT US</span>
      </p>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          If you have questions or concerns about this Privacy Policy, please contact us through one of the following
          ways:
        </span>
      </p>
      <ol style={{ margin: '0pt', paddingLeft: '0pt' }}>
        <li
          style={{
            marginTop: '14pt',
            marginLeft: '54.5pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '4pt',
            fontFamily: 'Times New Roman',
            fontSize: '12pt',
          }}
        >
          via our email:{' '}
          <a className={styles.link} href="mailto:support@bofp.co">
            support@bofp.co
          </a>
          ;
        </li>
        <li
          style={{
            marginLeft: '54.5pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '4pt',
            fontFamily: 'Times New Roman',
            fontSize: '12pt',
          }}
        >
          via our in-app support chat;
        </li>
        <li
          style={{
            marginLeft: '54.5pt',
            marginBottom: '12pt',
              lineHeight: 'normal',
            paddingLeft: '4pt',
            fontFamily: 'Times New Roman',
            fontSize: '12pt',
          }}
        >
          if you have a concern or compliance regarding our treatment of your personal data you can contact the relevant
          data protection authority. You can find contact details of data protection authority at{' '}
          <a
            href="https://edpb.europa.eu/about-edpb/board/members_en"
            target="_blank"
            style={{ textDecoration: 'none' }}
            rel="noreferrer"
          >
            <u>
              <span style={{ color: '#ff313f' }}>https://edpb.europa.eu/about-edpb/board/members_en</span>
            </u>
          </a>
          . For more information on which authority to contact, please email us here{' '}
          <a className={styles.link} href="mailto:support@bofp.co">
            support@bofp.co
          </a>
          .
        </li>
      </ol>
      <p
        style={{
          marginBottom: '12pt',
          lineHeight: 'normal',
          fontSize: '12pt',
        }}
      >
        <span style={{ fontFamily: 'Times New Roman' }}>
          For information collected under this Privacy Policy, the data controller is BATTLE OF PREDICTIONS TECHNOLOGY
          INC, a company under laws of the United States of America, with registered address at: 2546 e 17th street, 2nd
          floor, BROOKLYN, NY 11235.
        </span>
      </p>
    </>
  );
}

export default PrivacyList;
