import React from 'react';
import { IProvider } from '../../types';
import { ProviderItem } from './ProviderItem';

interface IProvidersListProps {
  list: IProvider[];
}

function ProvidersList({ list }: IProvidersListProps) {
  return (
    <ul>
      {list.map((item, index) => (
        <ProviderItem key={index} item={item} />
      ))}
    </ul>
  );
}

export default ProvidersList;
