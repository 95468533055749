import React, {useContext} from 'react';
import Basketball_leaderboard_points_l1_buff from '../../assets/images/buffs/Basketball_leaderboard_points_l1_buff.png';
import Basketball_leaderboard_points_l2_buff from '../../assets/images/buffs/Basketball_leaderboard_points_l2_buff.png';
import Basketball_leaderboard_points_l3_buff from '../../assets/images/buffs/Basketball_leaderboard_points_l3_buff.png';
import Bonus_coins_earned_l1_buff from '../../assets/images/buffs/Bonus_coins_earned_l1_buff.png';
import Bonus_coins_earned_l2_buff from '../../assets/images/buffs/Bonus_coins_earned_l2_buff.png';
import Bonus_coins_earned_l3_buff from '../../assets/images/buffs/Bonus_coins_earned_l3_buff.png';
import Red_card_cancel_buff_token from '../../assets/images/buffs/Cancel_red_card.png';
import Change_match_buff from '../../assets/images/buffs/Change_match_buff.png';
import Change_score_buff_token from '../../assets/images/buffs/Change_score.png';
import Additional_goal_buff_token from '../../assets/images/buffs/Forward.png';
import Freeze_match_buff from '../../assets/images/buffs/Freeze_match_buff.png';
import Goalkeeper_buff_token from '../../assets/images/buffs/Goalkeeper.png';
import Treatment_buff_token from '../../assets/images/buffs/Heal.png';
import Hockey_leaderboard_points_l1_buff from '../../assets/images/buffs/Hockey_leaderboard_points_l1_buff.png';
import Hockey_leaderboard_points_l2_buff from '../../assets/images/buffs/Hockey_leaderboard_points_l2_buff.png';
import Hockey_leaderboard_points_l3_buff from '../../assets/images/buffs/Hockey_leaderboard_points_l3_buff.png';
import Injury_buff_token from '../../assets/images/buffs/Injure.png';
import Penalty_taker_buff_token from '../../assets/images/buffs/Kicker.png';
import Soccer_leaderboard_points_l1_buff from '../../assets/images/buffs/Soccer_leaderboard_points_l1_buff.png';
import Soccer_leaderboard_points_l2_buff from '../../assets/images/buffs/Soccer_leaderboard_points_l2_buff.png';
import Soccer_leaderboard_points_l3_buff from '../../assets/images/buffs/Soccer_leaderboard_points_l3_buff.png';
import Tennis_leaderboard_points_l1_buff from '../../assets/images/buffs/Tennis_leaderboard_points_l1_buff.png';
import Tennis_leaderboard_points_l2_buff from '../../assets/images/buffs/Tennis_leaderboard_points_l2_buff.png';
import Tennis_leaderboard_points_l3_buff from '../../assets/images/buffs/Tennis_leaderboard_points_l3_buff.png';
import Prediction_after_start_buff_token from '../../assets/images/buffs/Time_machine.png';
import Penalty_ignore_total_buff_token from '../../assets/images/buffs/Total_fix.png';
import buffs_annihilation from '../../assets/images/buffs/buffs_annihilation.png';
import buffs_destroy from '../../assets/images/buffs/buffs_destroy.png';
import buffs_offside from '../../assets/images/buffs/buffs_offside.png';
import buffs_shield_base from '../../assets/images/buffs/buffs_shield_base.png';
import buffs_shield_golden from '../../assets/images/buffs/buffs_shield_golden.png';
import buffs_shield_golden_double from '../../assets/images/buffs/buffs_shield_golden_double.png';
import buffs_unsportsmanlike from '../../assets/images/buffs/buffs_unsportsmanlike.png';
import buffs_var from '../../assets/images/buffs/buffs_var.png';
import { BuffShatteringTableLine, IBrProps, IBSectionProps, IBTextProps } from './types';
import { I18nContext } from '../../store/I18nStore';
import Header from "./cmp/Header";

type ImageType = {
  [key: string]: string;
};

const imgs: ImageType = {
  Basketball_leaderboard_points_l1_buff,
  Basketball_leaderboard_points_l2_buff,
  Basketball_leaderboard_points_l3_buff,
  Bonus_coins_earned_l1_buff,
  Bonus_coins_earned_l2_buff,
  Bonus_coins_earned_l3_buff,
  Red_card_cancel_buff_token,
  Change_score_buff_token,
  Change_match_buff,
  Additional_goal_buff_token,
  Freeze_match_buff,
  Goalkeeper_buff_token,
  Treatment_buff_token,
  Hockey_leaderboard_points_l1_buff,
  Hockey_leaderboard_points_l2_buff,
  Hockey_leaderboard_points_l3_buff,
  Injury_buff_token,
  Penalty_taker_buff_token,
  Soccer_leaderboard_points_l1_buff,
  Soccer_leaderboard_points_l2_buff,
  Soccer_leaderboard_points_l3_buff,
  Tennis_leaderboard_points_l1_buff,
  Tennis_leaderboard_points_l2_buff,
  Tennis_leaderboard_points_l3_buff,
  Prediction_after_start_buff_token,
  Penalty_ignore_total_buff_token,
  Opponent_slot_block_buff_token: buffs_shield_base,
  Opponent_slot_block_2_buff_token: buffs_shield_golden,
  All_slots_block_buff_token: buffs_shield_golden_double,
  Destroy_buff_token: buffs_destroy,
  Destroy_all_buff_token: buffs_annihilation,
  Red_cards_buff_token: buffs_unsportsmanlike,
  Var_buff_token: buffs_var,
  No_goals_buff_token: buffs_offside,
};

function capitalizeFirstLetter(input: string): string {
  if (!input) return '';
  return input.charAt(0).toUpperCase() + input.slice(1);
}

function FaqBuffShattering() {
  const { i18n, getT } = useContext(I18nContext);

  function BText(props: IBTextProps) {
    return (
      <>
        <div
          style={{
            fontFamily: 'Inter',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '16px',
            letterSpacing: '-0.24px',
          }}
          dangerouslySetInnerHTML={{ __html: getT(props.t).replaceAll('\n', '<br>') }}
        />
      </>
    );
  }

  function BBuffTable() {
    const json = getT('buff_shatter_table');
    if (json === 'buff_shatter_table') return null;

    const list = JSON.parse(json);

    return (
      <table>
        <thead>
          <tr>
            <th></th>
            <th>{getT('buff_shatter_table_buff_column')}</th>
            <th>{getT('buff_shatter_table_fragments_column')}</th>
          </tr>
        </thead>
        <tbody>
          {list.map((line: BuffShatteringTableLine) => (
            <tr key={line.Buff}>
              <td style={{ minWidth: '24px' }}>
                <img style={{ width: '24px' }} src={imgs[capitalizeFirstLetter(line.Buff.toLowerCase())]} alt={line.Buff.toLowerCase()} />
              </td>
              <td>
                <BText t={'name_' + line.Buff.toLowerCase()} />
              </td>
              <td>{line.Fragments}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  function BSection(props: IBSectionProps) {
    const style = {
      fontFamily: 'Inter',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.24px',
    };

    if (props.big) {
      style.fontSize = '20px';
    }

    return (
      <div style={style} id={props.t}>
        {getT(props.t)}
      </div>
    );
  }

  function Br(props: IBrProps) {
    const arr = [];

    for (let i = 0; i < (props.length || 1); i++) {
      arr.push(i);
    }

    return (
      <>
        {arr.map((_, i) => (
          <br key={i} />
        ))}
      </>
    );
  }

  if (i18n === null) {
    return null;
  }

  return (
    <>
      <Header />
      <div style={{ textAlign: 'left', padding: '15px' }}>
        <BSection t={'page_title_shatter'} big={true} />

        <Br />
        <BText t={'page_description_shatter'} />

        <Br length={1} />

        <BBuffTable />
      </div>
    </>
  );
}

export default FaqBuffShattering;
