import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {I18nContext} from '../../../store/I18nStore';
import {EuroQuestionsSet, UserProfile} from "../types";
import {ROOT} from "../../../constants";
import useOnScreen from "./useOnScreen";
import useNavigateWithCurrentParams from "./useNavigateWithCurrentParams";

const intervals = {} as any;

const styles: { [key: string]: React.CSSProperties } = {

  card: {
    fontFamily: 'DrukCyr-MediumItalic',
    textTransform: 'uppercase',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    //minHeight: '66px',
    padding: '12px',
    background: 'white',
    gap: '8px',
    border: '1px #CACACE solid',
    borderRadius: '12px',
  },
  cardActive: {
    boxShadow: '2px 4px 24px #6E4CD9',
    border: '1px #6E4CD9 solid',
  },
  cardHeader: {
    display: 'flex',
    gap: '2px',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
  },
  cardHeaderDiv1: {
    whiteSpace: 'nowrap',
    fontSize: '24px',
    lineHeight: '30px',
  },
  cardHeaderDiv2: {
    whiteSpace: 'nowrap',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#C5B7F0',
  },
  cardHeaderDiv3: {
    whiteSpace: 'nowrap',
    fontSize: '24px',
    lineHeight: '30px',
    color: '#6E4CD9'
  },
  cardBoolets: {
    display: 'flex',
    gap: '2px',
  },
  cardBoolet: {
    flex: '1 1 0',
    height: '4px',
    background: '#E4E4E7',
    borderRadius: '4px',
  },
  gray: {
    color: '#CACACE',
  },
  green: {
    color: '#00D0C2',
  }
};

function getTimeDifference(startDate: Date, endDate: Date): string {
  let difference = Math.abs(endDate.getTime() - startDate.getTime());
  let hours = Math.floor(difference / (1000 * 60 * 60));
  difference -= hours * 1000 * 60 * 60;
  let minutes = Math.floor(difference / (1000 * 60));
  difference -= minutes * 1000 * 60;
  let seconds = Math.floor(difference / 1000);
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}


function formatDate(dateString: string) {
  const date = new Date(dateString);

  if (Math.abs(date.getTime() - Date.now()) < 1000 * 60 * 60 * 24) {
    const id = `time_${date.getTime()}_${Math.random()}`;
    setInterval(() => {
      const element = document.getElementById(id);
      if (element) {
        element.innerHTML = getTimeDifference(date, new Date());
      }
    }, 1000)
    return <span id={id}>{getTimeDifference(date, new Date())}</span>
  } else {
    const day = date.getDate();
    const month = date.toLocaleString('en-US', {month: 'long'});
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day} ${month}, ${hours}:${minutes}`;
  }
}

const onScreenElements: { [key: string]: boolean } = {};

function Question(props: { question?: EuroQuestionsSet, profile?: UserProfile, onClick?: () => void }) {
  const navigate = useNavigateWithCurrentParams();
  const ref = useRef<HTMLDivElement>(null);
  const [activeQuestion, setActiveQuestion] = useState<EuroQuestionsSet | undefined>(props.question);

  const onActiveClick = useCallback(() => {
    if (['ACTIVE', 'PREDICTED', 'COMPLETED'].indexOf(activeQuestion?.status || '') > -1) {
      navigate(ROOT.euro2024Prediction
        .replace(":id", activeQuestion?.id + '')
        .replace(":setStatus", activeQuestion?.status + '')
      )
    }
  }, [activeQuestion, navigate]);

  onScreenElements[activeQuestion?.id || 'trash'] = useOnScreen(ref, '0px', 'ACTIVE' === props?.question?.status).position === 'below';
  onScreenElements["trash"] = false;
  const goDownElement = document.getElementById("goDown");
  if (goDownElement) {
    if (Object.values(onScreenElements).includes(true)) {
      goDownElement.style.display = 'flex';
      goDownElement.onclick = () => {
        const element = document.querySelector('.question_ACTIVE');
        element?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
      }
    } else {
      goDownElement.style.display = 'none';
    }
  }
  //console.log(onScreenElements)

  const {i18n, getT, setLang, lang} = useContext(I18nContext);
  if (i18n === null) {
    return null;
  }

  if (activeQuestion) {
    if (activeQuestion.status === 'WAITING') {
      intervals[activeQuestion.id] = setInterval(()=>{
        if (new Date(activeQuestion!.startDate).getTime() < Date.now()) {
          clearInterval(intervals[activeQuestion!.id]);
          setActiveQuestion({...activeQuestion, status:'ACTIVE'} as EuroQuestionsSet);
        }
      }, 1000);
    } else if (activeQuestion.status === 'ACTIVE') {
      intervals[activeQuestion.id] = setInterval(()=>{
        if (new Date(activeQuestion!.endDate).getTime() < Date.now()) {
          clearInterval(intervals[activeQuestion!.id]);
          setActiveQuestion({...activeQuestion, status:'MISSED'} as EuroQuestionsSet);
        }
      }, 1000);
    } else if (activeQuestion.status === 'PREDICTED') {
      intervals[activeQuestion.id] = setInterval(() => {
        if (new Date(activeQuestion!.endDate).getTime() < Date.now()) {
          clearInterval(intervals[activeQuestion!.id]);
          setActiveQuestion({...activeQuestion, status: 'COMPLETED'} as EuroQuestionsSet);
        }
      }, 1000);
    }
  }

  return (
    <div
      onClick={props.onClick}
      style={{
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        display: 'flex'
      }}
    >
      {props.profile &&
        <div style={styles.card}>
          <div style={styles.cardHeader}>
            <div style={{...styles.cardHeaderDiv1, display: 'flex'}}>
              <span style={{fontSize: '16px'}}>#{props.profile?.leaderboard?.position}</span>
              <span style={{paddingLeft: '8px', paddingRight: '8px'}}>
                <img style={{width: 32, height: 32, borderRadius: 12}} src={props!.profile!.user!.photo!}/>
              </span>
              {props.profile.user.profileName}
            </div>
            <div style={styles.cardHeaderDiv1}>
              {props.profile?.leaderboard?.points}
              <span
                style={{fontSize: '16px'}}>&nbsp;{getT('euro_pts_plural', props.profile?.leaderboard?.points)}</span>
            </div>
          </div>
        </div>
      }
      {activeQuestion &&
        <div ref={ref}
             className={`question_${activeQuestion.status}`}
             style={{...styles.card, ...('ACTIVE' === activeQuestion.status ? styles.cardActive : {})}}
             onClick={onActiveClick}>
          <div style={styles.cardHeader}>
            <div
              style={{...styles.cardHeaderDiv1, ...(['MISSED', 'WAITING'].indexOf(activeQuestion.status) > -1 ? styles.gray : {})}}>{activeQuestion.name}</div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {activeQuestion.status !== 'COMPLETED' && <div
                style={{...styles.cardHeaderDiv2, ...(['MISSED', 'WAITING', 'PREDICTED'].indexOf(activeQuestion.status) > -1 ? styles.gray : {})}}>
                {activeQuestion.status === 'MISSED' ?
                  getT('euro_button_missed') : (
                    activeQuestion.status === 'WAITING' ?
                      formatDate(activeQuestion.startDate) :
                      formatDate(activeQuestion.endDate)
                  )
                }
              </div>}
              {activeQuestion.status === 'ACTIVE' && <>
                <div style={styles.cardHeaderDiv2}>&nbsp;&nbsp;•&nbsp;&nbsp;</div>
                <div style={styles.cardHeaderDiv3}>{getT('euro_button_predict')}</div>
              </>}
              {activeQuestion.status === 'PREDICTED' && <>
                <div style={{...styles.cardHeaderDiv2, ...styles.gray}}>&nbsp;&nbsp;•&nbsp;&nbsp;</div>
                <div style={{...styles.cardHeaderDiv2, ...styles.green}}>{getT('euro_button_predicted')}</div>
              </>}
              {activeQuestion.status === 'COMPLETED' && <>
                <div style={{...styles.cardHeaderDiv2, ...styles.gray}}>
                  {activeQuestion.answers.reduce((acc, answer) => acc + (answer === 'WIN' ? 1 : 0), 0)}
                  /{activeQuestion.answers.length}
                  &nbsp;&nbsp;•&nbsp;&nbsp;
                </div>
                <div style={{...styles.cardHeaderDiv1}}>
                  {activeQuestion.points}
                  <span style={{fontSize: '16px'}}>&nbsp;{getT('euro_pts_plural', activeQuestion.points)}</span>
                </div>
              </>}
            </div>
          </div>
          {['ACTIVE', 'PREDICTED', 'COMPLETED'].indexOf(activeQuestion.status) > -1 && <div style={styles.cardBoolets}>
            {activeQuestion.answers.map((answer, index) => (
              <div key={index} style={{
                ...styles.cardBoolet,
                background: answer === 'WIN' ? '#1EC51B' : answer === 'LOSE' ? '#FF4133' : '#E4E4E7'
              }}></div>
            ))}
          </div>}
        </div>}
    </div>
  );
}

export default Question;
