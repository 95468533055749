import React, {
  forwardRef,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle, useMemo,
  useRef,
  useState
} from 'react';
import Header from './cmp/Header';
import {I18nContext} from '../../store/I18nStore';
import {useParams} from 'react-router-dom';
import MainImage from "./cmp/MainImage";
import useAsyncEffect from "use-async-effect";
import {AnswerVariant, Stage, Question, Answer, UserAnswer, QuestionProps, EuroQuestionsSet, API_URL} from "./types";
import {genHeader} from "./cmp/genHeader";
import head1 from "../../assets/images/euro2024/head1.png";
import head2 from "../../assets/images/euro2024/head2.png";
import head3 from "../../assets/images/euro2024/head3.png";
import head4 from "../../assets/images/euro2024/head4.png";
import head5 from "../../assets/images/euro2024/head5.png";
import head6 from "../../assets/images/euro2024/head6.png";
import head7 from "../../assets/images/euro2024/head7.png";
import searchImg from "../../assets/images/euro2024/search.png";
import Skeleton from "./cmp/Skeleton";
import useNavigateWithCurrentParams from "./cmp/useNavigateWithCurrentParams";
import chevron_right from "../../assets/images/euro2024/chevron_right.svg";
import chevron_left from "../../assets/images/euro2024/chevron_left.svg";
import loaderImg from "../../assets/images/euro2024/loader.svg";


import close_gray from '../../assets/images/euro2024/simple/close.svg';
import minus_gray from '../../assets/images/euro2024/simple/minus.svg';
import plus_gray from '../../assets/images/euro2024/simple/plus.svg';
import check_gray from '../../assets/images/euro2024/simple/check.svg';

import close_red from '../../assets/images/euro2024/simple/close_red.svg';
import minus_red from '../../assets/images/euro2024/simple/minus_red.svg';
import plus_red from '../../assets/images/euro2024/simple/plus_red.svg';
import check_red from '../../assets/images/euro2024/simple/check_red.svg';


import close_green from '../../assets/images/euro2024/simple/close_green.svg';
import minus_green from '../../assets/images/euro2024/simple/minus_green.svg';
import plus_green from '../../assets/images/euro2024/simple/plus_green.svg';
import check_green from '../../assets/images/euro2024/simple/check_green.svg';


import close_purp from '../../assets/images/euro2024/simple/close_purp.svg';
import minus_purp from '../../assets/images/euro2024/simple/minus_purp.svg';
import plus_purp from '../../assets/images/euro2024/simple/plus_purp.svg';
import check_purp from '../../assets/images/euro2024/simple/check_purp.svg';
import Input from "./cmp/Input";
import question from "./cmp/Question";

const COLORS_IMAGES = {
  close_gray,
  minus_gray,
  plus_gray,
  check_gray,
  close_red,
  minus_red,
  plus_red,
  check_red,
  close_green,
  minus_green,
  plus_green,
  check_green,
  close_purp,
  minus_purp,
  plus_purp,
  check_purp
};


const styles: { [key: string]: React.CSSProperties } = {
  header: {
    fontFamily: 'Inter',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '12px',
    letterSpacing: '0.6px',
  },
  groupName: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '-0.24px',
    fontFamily: 'Inter',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    color: 'white',
    paddingTop: '12px',
    marginBottom: '12px',
  },

  questionName: {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    //letterSpacing: '0.6px',
    fontFamily: 'Inter',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    color: 'black',
    textAlign: 'center',
  },
  scores: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    //letterSpacing: '0.6px',
    //textTransform: 'uppercase',
    fontFamily: 'Inter',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    //paddingBottom: '12px',
    paddingTop: '8px',
    flexDirection: 'column',
  },

  textH1: {
    color: 'white',
    textAlign: 'center',
    fontSize: '38px',
    fontWeight: 700,
    lineHeight: '48px', // 120%
    textTransform: 'uppercase',
    fontFamily: 'DrukCyr-MediumItalic',
    whiteSpace: 'nowrap',
  },

  questionCont: {
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '12px',
    padding: '12px',
    marginBottom: '12px',
    letterSpacing: '-0.24px'
  },
  pts: {display: 'flex', alignItems: 'center', fontSize: '12px', color: '#8B8C95'},

  question: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '48px',
    padding: '12px',
    marginBottom: '12px',
    borderRadius: '12px',
    backgroundColor: '#F6F6F7',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '16px',
    textTransform: 'none',
  }
};

export interface ChildRef {
  getState: () => any;
}

function Prediction() {
  const {i18n, getT, scheme, accessToken} = useContext(I18nContext);
  const [data, setData] = React.useState<Stage | null>(null);
  const [error, setError] = React.useState<{ error: string } | null>(null);
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const setId = useParams().id;
  const setStatus = useParams().setStatus;

  const childRefs = useRef<ChildRef[]>([]);
  const navigate = useNavigateWithCurrentParams();

  function getColor() {
    switch (setId) {
      case '1' :
        return {image: head1, color: '#3553D1'};
      case '2' :
        return {image: head2, color: '#75C07F'};
      case '3' :
        return {image: head3, color: '#D84532'};
      case '4' :
        return {image: head4, color: '#3553D1'};
      case '5' :
        return {image: head5, color: '#F3D562'};
      case '6' :
        return {image: head6, color: '#75C07F'};
      case '7' :
        return {image: head7, color: '#3553D1'};
      default :
        return {image: head1, color: '#3553D1'};
    }
  }

  const addRef = (el: any, index: any) => {
    if (el) {
      childRefs.current[index] = el;
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const childStates = childRefs.current.map(ref => ref.getState());

    const request: { predictions: any[] } = {predictions: []}
    childStates.forEach((item) => {
      if (item.choose !== null) {
        if (item.question.type === 'SORT') {
          if (item.choose.length === item.question.answers.length) {
            request.predictions.push({
              questionId: item.question.id,
              answers: item.choose
            });
          }
        } else if (item.question.type === 'SIMPLE') {
          request.predictions.push({
            questionId: item.question.id,
            answers: [{
              answerId: item.choose
            }]
          });
        } else if (item.question.type === 'LIST') {
          request.predictions.push({
            questionId: item.question.id,
            answers: [{
              answerId: item.choose.id
            }]
          });
        } else if (item.question.type === 'WIN1_DRAW_WIN2') {
          request.predictions.push({
            questionId: item.question.id,
            answers: [{
              answerId: item.choose
            }]
          });
        }
      }
    });

    const countOfQuestions = data?.groups.reduce((acc, group) => acc + group.questions.length, 0);
    const isAllAnswers = request.predictions.length === countOfQuestions;

    if (isAllAnswers) {
      if (!submitting) {
        const url = `${API_URL}/v2/euro/sets/${setId}/predict`;
        const response = await fetch(url, {
          method: 'POST',
          headers: genHeader(accessToken, url),
          body: JSON.stringify(request),
        });
        if (response.status === 200) {
          setError(null);
          navigate('/euro2024');
        }
        setSubmitting(false);
      }
    } else {
      setSubmitting(false);
      setError({error: getT('euro_question_predict_button_error')})
      setInterval(() => {
        setError(null)
      }, 3000)
    }
  };


  useEffect(() => {
    if (data) {
      document.body.style.backgroundColor = getColor().color;
    }
    return () => {
      document.body.style.backgroundColor = '#F6F6F7';
    };
  }, [data, getColor]);

  useAsyncEffect(async () => {
    const url = `${API_URL}/v2/euro/sets/${setId}/questions`;
    const response = await fetch(url, {
      method: 'GET',
      headers: genHeader(accessToken, url),
    });
    const data: Stage = await response.json();
    setTimeout(() => {
      setData(data)
    }, 0);
  }, []);

  if (i18n === null) {
    return null;
  }

  function hideError() {
    error && setError(null);
  }

  if (data) {
    const interval1 = setInterval(() => {
      if (new Date(data.endDate).getTime() < Date.now()) {
        clearInterval(interval1);
        setData({...data});
      }
    }, 1000);
  }


  return (
    <>
      <Header label={data?.name || ''}/>
      {data ? <>
        <MainImage image={getColor().image} imageStyle={{height: '208px', minHeight: '0px'}}>
          <div style={{...styles.textH1, marginTop: '-28px'}}>{data?.name || ''}</div>
        </MainImage>
        <div style={{height: '24px'}}/>
        <div onClick={hideError} style={{
          padding: '12px',
        }}>
          {data?.groups?.map((group, index) => (
            <Fragment key={index}>
              <div style={styles.groupName} key={index}>
                {group.name}
              </div>
              {group.questions.map((question, index) => (
                <Fragment key={index}>
                  <div style={styles.questionCont}>
                    <QuestionHeader question={question} userAnswers={data?.userAnswers}/>
                    <div style={{...styles.scores, letterSpacing: '-0.24px'}}>
                      {question.type === 'WIN1_DRAW_WIN2' &&
                        <X2Question ref={(el) => addRef(el, question.id)} question={question} setStatus={setStatus} index={index}
                                    userAnswers={data?.userAnswers}/>}
                      {question.type === 'SORT' &&
                        <SortQuestion ref={(el) => addRef(el, question.id)} question={question} setStatus={setStatus} index={index}
                                      userAnswers={data?.userAnswers}/>}
                      {question.type === 'SIMPLE' &&
                        <SimpleQuestion ref={(el) => addRef(el, question.id)} question={question} setStatus={setStatus} index={index}
                                        userAnswers={data?.userAnswers}/>}
                      {question.type === 'LIST' &&
                        <ListQuestion ref={(el) => addRef(el, question.id)} question={question} setStatus={setStatus} index={index}
                                      userAnswers={data?.userAnswers}/>}
                    </div>
                  </div>
                </Fragment>
              ))}
            </Fragment>
          ))}
          {(data?.groups?.filter((g) => g.questions.filter((f) => f.isProcessed).length > 0).length === 0 &&
              new Date(data.endDate).getTime() > Date.now()
            ) &&
            <div onClick={handleSubmit} style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '48px',
              padding: '12px',
              marginTop: '24px',
              marginBottom: '12px',
              borderRadius: '12px',
              backgroundColor: !submitting ? '#F6F6F7' : '#EDEDEF',
              fontFamily: 'Inter',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '20px',
              textTransform: 'none',
              letterSpacing: '-0.24px'
            }}>
              {submitting && <div style={{display: 'flex', alignItems: 'center'}}>
                <img style={{animation: 'rotate 4s linear infinite'}} src={loaderImg}/>
              </div>}
              {!submitting && <div style={{display: 'flex', alignItems: 'center'}}>
                {(data?.userAnswers?.length || 0) > 0 ?
                  <div style={{}}>{getT('euro_question_change_predictions_button')}</div> :
                  <div style={{}}>{getT('euro_question_predict_button')}</div>
                }
              </div>}
            </div>
          }
        </div>
      </> : <>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
          <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
          <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
          <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
          <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
          <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
          <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
          <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
          <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
        </div>
      </>}
      {error && <div onClick={hideError} style={{
        position: 'fixed',
        width: '100%',
        height: '68px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: '0px',
        padding: '12px',
        background: 'linear-gradient(180deg, transparent 20%, #FF4133 120%)'
      }}>
        <div style={{
          display: "flex",
          backgroundColor: '#FF4133',
          color: 'white',
          borderRadius: '12px',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '16px',
          fontFamily: 'Inter',
          padding: '12px',
          marginTop: '-20px',
          textAlign: 'center'
        }}>
          {error.error}
        </div>
      </div>}
    </>
  );
}

function QuestionHeader(props: { question: Question, userAnswers: UserAnswer[] }) {
  const {getT} = useContext(I18nContext);
  const qa = props.userAnswers?.filter((ua)=> ua.questionId === props.question.id)[0];

  let scores = {processed: qa?.pointsEarned, unprocessed: 0}
  switch (props.question.type) {
    case 'SORT':
      //scores.processed = getHighAnswersVariantsPointsProcessed(props.question.answers);
      scores.unprocessed = getSumHighAnswersVariantsPoints(props.question.answers);
      break;
    case 'SIMPLE':
      //scores.processed = getHighAnswersPointsProcessed(props.question.answers, props.userAnswers);
      scores.unprocessed = getHighAnswersPoints(props.question.answers);
      break;
    case 'LIST':
      //scores.processed = getHighAnswersPointsProcessed(props.question.answers, props.userAnswers);
      scores.unprocessed = getHighAnswersPoints(props.question.answers);
      break;
    case 'WIN1_DRAW_WIN2':
      //scores.processed = getHighAnswersPointsProcessed(props.question.answers, props.userAnswers);
      scores.unprocessed = getHighAnswersPoints(props.question.answers);
      break;
  }

  return <>
    <div style={styles.questionName}>{props.question.question}</div>
    <div style={styles.scores}>
      <div style={{paddingBottom: '12px'}}>
        {!props.question.isProcessed &&
          <div
            style={{color: '#8B8C95'}}>{scores.unprocessed}&nbsp;{getT('euro_question_card_max_pts', scores.unprocessed)}</div>}
        {props.question.isProcessed &&
          <div
            style={{color: scores.processed > 0 ? '#1EC51B' : '#f00'}}>{scores.processed}&nbsp;{getT('euro_pts_plural', scores.processed)}</div>}
      </div>
    </div>
  </>
}

const SortQuestion = forwardRef<ChildRef, QuestionProps>((props: {
  question: Question,
  index: number,
  userAnswers: UserAnswer[],
  setStatus?: string
}, ref) => {
  const [choose, setChoose] = useState<{
    answerId: number,
    variantId: number,
    index: number
  }[]>(getInitUsersAnswersForSort(props.userAnswers, props.question));
  const clickChoose = useCallback((answer: Answer) => {
    if (props.question.isProcessed || props.question.type === 'COMPLETED' || props.setStatus  === 'COMPLETED') return;
    if (choose.filter((item) => item.answerId === answer.id).length > 0) {
      setChoose(choose.filter(item => item.answerId !== answer.id));
    } else {
      const indexes = choose.map((item) => item.index).sort((a, b) => a - b);
      let firstFreeIndex = 0;

      if (indexes.indexOf(1) === -1) (firstFreeIndex = 1)
      else if (indexes.indexOf(2) === -1) (firstFreeIndex = 2)
      else if (indexes.indexOf(3) === -1) (firstFreeIndex = 3)
      else if (indexes.indexOf(4) === -1) (firstFreeIndex = 4)

      const variant = answer.variants.filter((item) => item.value === firstFreeIndex)[0];
      setChoose([...choose, {answerId: answer.id, variantId: variant.id, index: variant.value}]);
    }
  }, [choose, props.question.isProcessed, props.question.type]);

  const {getT} = useContext(I18nContext);

  useImperativeHandle(ref, () => ({
    getState: () => ({question: props.question, choose}),
  }));


  if (props.question.type === 'SORT') {
    return <Fragment key={props.index}>
      {props.question.answers.map((answer, index) => {
          const selected = choose.filter((item) => item.answerId === answer.id)[0];
          const isCorrect = answer.variants.filter((item) => item.id === selected?.variantId)[0]?.isCorrect;
          const color = (selectedAnswerCSS(!!selected, isCorrect, props.question.isProcessed));

          let pts: string | number = '';
          if (!props.question.isProcessed && !selected) {
            pts = pointMinMaxRange(answer.variants).join(" - ");
          } else {
            pts = answer.variants.filter((v) => v.id === selected.variantId)[0]?.points;
          }

          return <div key={index}
                      onClick={clickChoose.bind(null, answer)}
                      style={{
                        ...styles.question,
                        ...color,
                      }}>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>{answer.img && <img style={{width: "24px", height: '24px'}} src={answer.img}/>}</div>
              <div>&nbsp;{answer.answer}</div>
            </div>
            <div style={styles.pts}>
              <div>{pts} {getT('euro_pts_plural', pts)}</div>
              &nbsp;
              <div style={{
                width: "24px",
                height: "24px",
                backgroundColor: color.secondBackgroundColor,
                borderRadius: '8px',
                border: '1px solid #E4E4E7',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '600',
                lineHeight: '16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: "white"
              }}>{selected?.index || ''}</div>
            </div>
          </div>
        }
      )}
    </Fragment>
  } else {
    return null;
  }
});

const ListQuestion = forwardRef<ChildRef, QuestionProps>((props: {
  question: Question,
  index: number,
  userAnswers: UserAnswer[],
  setStatus?: string
}, ref) => {
  const {getT} = useContext(I18nContext);
  const [choosePopup, setChoosePopup] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [selected, setSelected] = useState<Answer | null>(getInitUsersAnswersForList(props.userAnswers, props.question));

  useImperativeHandle(ref, () => ({
    getState: () => ({question: props.question, choose: selected}),
  }));

  if (choosePopup) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }

  useEffect(() => {
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const filteredAnswers = useMemo(() => {
    return props.question.answers.filter((answer) => {
      if (search.length === 0) {
        return true;
      }
      return answer.answer.toLowerCase().includes(search.toLowerCase());
    });
  }, [search, props.question.answers]);

  function openPopup(opened: boolean) {
    if (props.question.isProcessed || props.question.type === 'COMPLETED' || props.setStatus  === 'COMPLETED') return;
    setChoosePopup(opened);
  }

  function setResultPopup(selected: Answer) {
    setSelected(selected);
    setChoosePopup(false);
  }


  const color = (selectedAnswerCSS(!!selected, selected?.isCorrect || false, props.question.isProcessed));
  if (props.question.type === 'LIST') {
    return !choosePopup ?
      <div style={{
        ...styles.question,
        ...color,
      }} onClick={openPopup.bind(null, true)}>
        {!selected &&
          <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
            <div>{getT('euro_question_list_placeholder')}</div>
            <img src={chevron_right}/>
          </div>}
        {selected && <>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div>{selected.img &&
              <img style={{width: "24px", height: '24px', borderRadius: '4px'}} src={selected.img}/>}</div>
            <div>{(!selected.img && getButtonTypeSymbol(selected, color)) &&
              <img style={{width: "24px", height: '24px'}}
                   src={getButtonTypeSymbol(selected, color)}/>}</div>
            <div>&nbsp;{selected.answer}</div>
          </div>
          <div style={styles.pts}>
            <div>{`${selected.points} ${getT('euro_pts_plural', selected.points)}`}</div>
            {!props.question.isProcessed && <img src={chevron_right}/>}
          </div>
        </>}
      </div> : <>
        <div style={{
          position: "fixed",
          zIndex: 101,
          width: '100%',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#F6F6F7',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '12px 12px 0 0',
          letterSpacing: '-0.24px',
          lineHeight: '20px',
          fontFamily: 'inter',
          fontSize: '16',
          padding: '15px',
          textTransform: 'none',
          overflow: 'none',
        }}>
          {/*<div style={{*/}
          {/*  width: '49px',*/}
          {/*  height: '4px',*/}
          {/*  backgroundColor: '#E4E4E7',*/}
          {/*  borderRadius: '4px',*/}
          {/*  marginTop: '12px',*/}
          {/*  marginBottom: '12px'*/}
          {/*}}></div>*/}
          <div style={{
            display: 'flex',
            width: '100%',
            paddingTop: '12px',
            paddingBottom: '12px',
            justifyContent: 'space-between'
          }}>
            <div style={{color: '#6E4CD9', minWidth: '24px', height: "24px"}} onClick={openPopup.bind(null, false)}>
              <img style={{width: '24px', height: "24px"}} width={24} height={24} src={chevron_left}/>
            </div>
            <div style={{
              textAlign: 'center',
              fontSize: '16px',
              fontWeight: '600',
              paddingLeft: '10px',
              paddingRight: '10px',
              paddingTop:'3px'
            }}>{props.question.question}</div>
            <div style={{visibility: "hidden", minWidth: '24px', height: "24px"}}><img src={chevron_left}/></div>
          </div>

          <Input value={search} onChange={(v) => setSearch(v)}
                 placeholder={getT('euro_question_list_search_placeholder')}/>

          <div style={{overflow: 'auto', width: '100%',}}>
            {filteredAnswers.map((answer, index) => (
              <div key={index} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                height: '48px',
                padding: '12px',
                marginBottom: '1px',
                borderRadius: index === 0 ? '12px 12px 0 0' : (index === props.question.answers.length - 1 ? '0 0 12px 12px' : '0'),
                backgroundColor: '#fff',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '16px',
                textTransform: 'none',
                ...(selected?.id === answer.id ? color : {})
              }} onClick={setResultPopup.bind(null, answer)}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div>{answer.img &&
                    <img style={{width: "24px", height: '24px', borderRadius: '4px'}} src={answer.img}/>}</div>
                  <div>{(!answer.img && getButtonTypeSymbol(answer, color)) &&
                    <img style={{width: "24px", height: '24px'}}
                         src={getButtonTypeSymbol(answer, color)}/>}</div>
                  <div>&nbsp;{answer.answer}</div>
                </div>
                <div style={styles.pts}>
                  <div>{`${answer.points} ${getT('euro_pts_plural', answer.points)}`}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
  } else {
    return null;
  }
});

const X2Question = forwardRef<ChildRef, QuestionProps>((props: {
  question: Question,
  index: number,
  userAnswers: UserAnswer[],
  setStatus?: string
}, ref) => {
  const {getT} = useContext(I18nContext);
  const [choose, setChoose] = useState<number | null>(getInitUsersAnswers(props.userAnswers, props.question));
  const clickChoose = useCallback((id: number) => {
    if (props.question.isProcessed || props.question.type === 'COMPLETED' || props.setStatus  === 'COMPLETED') return;
    setChoose(id);
  }, [props.question.isProcessed, props.setStatus]);

  useImperativeHandle(ref, () => ({
    getState: () => ({question: props.question, choose}),
  }));

  if (props.question.type === 'WIN1_DRAW_WIN2') {
    return <Fragment key={props.index}>
      {props.question.answers.map((answer, index) => {
        const color = (selectedAnswerCSS(choose === answer.id, answer.isCorrect, props.question.isProcessed));
        return <div key={index} style={{
          ...styles.question,
          ...color
        }} onClick={clickChoose.bind(null, answer.id)}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div>{answer.img && <img style={{width: "24px", height: '24px'}} src={answer.img}/>}</div>
            <div>{(!answer.img && answer.buttonType !== 'DEFAULT') &&
              <img style={{width: "24px", height: '24px'}} src={getButtonTypeSymbol(answer, color)}/>}</div>
            <div>&nbsp;{answer.answer}</div>
          </div>
          <div style={styles.pts}>
            <div>{`${answer.points} ${getT('euro_pts_plural', answer.points)}`}</div>
          </div>
        </div>
      })}
    </Fragment>
  } else {
    return null;
  }
});

const SimpleQuestion = forwardRef<ChildRef, QuestionProps>((props: {
  question: Question,
  index: number,
  userAnswers: UserAnswer[],
  setStatus?: string
}, ref) => {
  const {getT} = useContext(I18nContext);
  const [choose, setChoose] = useState<number | null>(getInitUsersAnswers(props.userAnswers, props.question));
  const clickChoose = useCallback((id: number) => {
    if (props.question.isProcessed || props.question.type === 'COMPLETED' || props.setStatus  === 'COMPLETED') return;
    setChoose(id);
  }, [props.question.isProcessed, props.question.type, props.setStatus]);

  useImperativeHandle(ref, () => ({
    getState: () => ({question: props.question, choose}),
  }));

  if (props.question.type === 'SIMPLE') {
    return <div key={props.index} style={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
      marginBottom: '12px',
      borderRadius: '12px',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '16px',
      textTransform: 'none',
    }}>
      {props.question.answers.map((answer, index) => {
        const color = (selectedAnswerCSS(choose === answer.id, answer.isCorrect, props.question.isProcessed));

        return <div key={index} style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: "column",
          //padding: '12px',
          marginRight: '6px',
          height: '94px',
          backgroundColor: '#F6F6F7',
          borderRadius: '12px',
          width: '49%',
          ...color
        }} onClick={clickChoose.bind(null, answer.id)}>
          <img
            style={{fontSize: '24px', paddingBottom: '8px'}}
            src={getButtonTypeSymbol(answer, color)}/>
          <div style={{textAlign: "center"}}>{answer.answer}</div>
          <div style={styles.pts}>{answer.points} {getT('euro_pts_plural', answer.points)}</div>
        </div>
      })}
    </div>
  } else {
    return null;
  }
});


function pointMinMaxRange(variants: AnswerVariant[]) {
  let minPoints = 1000000000;
  let maxPoints = 0;

  variants.forEach(item => {
    if (item.points < minPoints) {
      minPoints = item.points;
    }
    if (item.points > maxPoints) {
      maxPoints = item.points;
    }
  });

  return [minPoints, maxPoints];
}


function getHighAnswersVariantsPoints(answers: Answer[]) {
  let max = 0;
  answers.forEach(item => {
    item.variants.forEach(item => {
      if (item.points > max) {
        max = item.points;
      }
    });
  });
  return max;
}

function getSumHighAnswersVariantsPoints(answers: Answer[]) {
  let sum = 0;
  answers.forEach(item => {
    sum += pointMinMaxRange(item.variants)[1];
  });
  return sum;
}

/*
Если isProcessedtrue в ключ euro_question_card_earned_pts передавать сумму answers.variants.points где isCorrect true и variants.id есть в  userAnswers.answers.variantId (по факту в userAnswers лежат ответы пользователя мы смотрим какие из низ были правильными и отображаем кол-во очков набранных пользователем) отображать зеленым.
 */
function getHighAnswersVariantsPointsProcessed(answers: Answer[]) {
  let sum = 0;
  answers.forEach(item => {
    item.variants.forEach(item1 => {
      if (item1.isCorrect) {
        sum += item1.points;
      }
    });
  });
  return sum;
}

function getHighAnswersPoints(answers: Answer[]) {
  let max = 0;
  answers.forEach(item => {
    if (item.points > max) {
      max = item.points;
    }
  });
  return max;
}

function getHighAnswersPointsProcessed(answers: Answer[], userAnswers: UserAnswer[]) {
  const correctAns = answers.filter((ans) => ans.isCorrect)[0];
  if (correctAns) {
    const userAnswer = userAnswers.filter((userAnswer) => userAnswer.answers[0]?.answerId === correctAns.id)[0];
    if (userAnswer) {
      return correctAns.points;
    }
  }
  return 0;
}

function getInitUsersAnswers(userAnswers: UserAnswer[], question: Question) {
  return userAnswers.filter((item) => item.questionId === question.id)[0]?.answers?.map((item) => {
    return item.answerId;
  })[0] || null;
}

function getInitUsersAnswersForList(userAnswers: UserAnswer[], question: Question) {
  const userAnswer = userAnswers.filter((userAnswer) => userAnswer.questionId === question.id)[0];
  if (userAnswer) {
    return question.answers.filter((answer) => answer.id === userAnswer.answers[0].answerId)[0] || null;
  } else {
    return null;
  }
}

function getInitUsersAnswersForSort(userAnswers: UserAnswer[], question: Question) {
  const userAnswer = userAnswers.filter((userAnswer) => userAnswer.questionId === question.id)[0]?.answers;
  if (userAnswer?.length > 0) {
    return userAnswer.map((item, index) => {
      return {
        answerId: item.answerId,
        variantId: item.variantId,
        index: question.answers.filter((answer) => answer.id === item.answerId)[0].variants.filter((variant) => variant.id === item.variantId)[0].value
      }
    });
  } else {
    return [];
  }
}


function getButtonTypeSymbol(answer: Answer, color: any): any {
  switch (answer.buttonType) {
    case 'PLUS':
      // @ts-ignore
      return COLORS_IMAGES[`plus_${color.imageColorName}`];
    case 'MINUS':
      // @ts-ignore
      return COLORS_IMAGES[`minus_${color.imageColorName}`];
    case 'CROSS':
      // @ts-ignore
      return COLORS_IMAGES[`close_${color.imageColorName}`];
    case 'CHECK_MARK':
      // @ts-ignore
      return COLORS_IMAGES[`check_${color.imageColorName}`];
    default:
      return answer.img || undefined;
  }
}

function selectedAnswerCSS(isSelected: boolean, isCorrect: boolean, isProcessed: boolean) {
  if (isSelected) {
    if (isProcessed) {
      if (isCorrect) {
        return {
          backgroundColor: '#E4F8E4',
          secondBackgroundColor: '#1EC51B',
          imageColorName: 'green',
          border: '1px solid #A5E8A4',
        }
      } else {
        return {
          backgroundColor: '#FFE8E7',
          border: '1px solid #FFB3AD',
          imageColorName: 'red',
          secondBackgroundColor: '#FF4133',
        }
      }
    } else {
      return {
        backgroundColor: '#F0EAFC',
        border: '1px solid #C5B7F0',
        imageColorName: 'purp',
        secondBackgroundColor: '#6E4CD9'
      }
    }
  } else {
    return {
      secondBackgroundColor: '#FFF',
      imageColorName: 'gray',
    };
  }
}


export default Prediction;
