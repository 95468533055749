import React, {useContext} from 'react';
import {Logo} from '../../components/shared/Logo';
import {Title} from '../../components/shared/Title';
import {ProvidersList} from '../../components/ProvidersList';
import {PROVIDERS, PROVIDERS_RU} from '../../constants';
import Header from "../Faq/cmp/Header";
import {I18nContext} from "../../store/I18nStore";

function Providers() {
  const {lang} = useContext(I18nContext);

  return (
    <>
      <Header/>
      <div style={{textAlign: 'left', padding: '15px'}}>
        <Logo/>
        {lang === 'ru'?<Title title="Third party service providers" updated="November 03, 2023"/>
        :<Title title="Поставщики услуг" updated="Ноябрь 03, 2023"/>}
        {lang === 'ru'?<ProvidersList list={PROVIDERS_RU}/>:<ProvidersList list={PROVIDERS}/>}
      </div>
    </>
  );
}

export default Providers;
