import React from 'react';
import { IProvider } from '../../../types';

import styles from './providerItem.module.scss';

interface IProviderItemProps {
  item: IProvider;
}

function ProviderItem({ item }: IProviderItemProps) {
  const { title, subtitle, link, type } = item;
  return (
    <li className={styles.item}>
      <h3 className={styles.item__title}>{title}</h3>
      <p className={styles.item__subtitle}>{subtitle}</p>
      <a className={styles.item__link} href={link}>
        {link}
      </a>
      <p className={styles.item__type}>{type}</p>
    </li>
  );
}

export default ProviderItem;
