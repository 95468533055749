import {sha1} from 'js-sha1';
import {encode} from 'js-base64';

export function genHeader(accessToken: string, url: string): any {
  const timeStamp = Date.now();
  const secret = sha1(sha1(encode(`${url}${timeStamp}${accessToken}putinhuilo`)));
  return {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + accessToken,
    'X-identifier': secret,
    'X-time': timeStamp
  };
}


