import React, {useContext, useEffect, useState} from 'react';
import searchImg from "../../../assets/images/euro2024/search.svg";
import clearImg from "../../../assets/images/euro2024/clear_circle.svg";


function Input(props: { value?: string, placeholder: string, onChange: (value: string) => void}) {
  const [focused, setFocused] = useState<boolean>(false);
  const [search, setSearch] = useState<string>(props.value || '');


  function onfocus() {
    setFocused(true);
  }

  function onunfocus() {
    setFocused(false);
  }

  function onsearch(search: string) {
    setSearch(search);
    props.onChange(search);
  }

  return <div style={{
    width: '100%',
    paddingTop: '8px',
    paddingBottom: '8px',
    display: 'flex',
    alignItems: 'center',
  }}>
    <div style={{
      width: '40px',
      height: '48px',
      borderRadius: '12px 0 0 12px',
      backgroundColor: '#FFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: focused ? '1px solid #6E4CD9' : 0,
      borderLeft: focused ? '1px solid #6E4CD9' : 0,
      borderBottom: focused ? '1px solid #6E4CD9' : 0,
    }}>
      <img src={searchImg} alt="Search" style={{
        width: '20px',
        height: '20px',
      }}/>
    </div>
    <input type='text'
           onFocus={onfocus}
           onBlur={onunfocus}
           placeholder={props.placeholder}
           onChange={(e) => onsearch(e.target.value)}
           value={search}
           style={{
             width: '100%',
             height: '48px',
             border: 0,
             borderRadius: 0,
             borderTop: focused ? '1px solid #6E4CD9' : 0,
             borderBottom: focused ? '1px solid #6E4CD9' : 0,
             backgroundColor: '#FFF'

           }}/>
    <div onClick={() => onsearch('')} style={{
      width: '40px',
      height: '48px',
      borderRadius: '0 12px 12px 0',
      backgroundColor: '#FFF',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderTop: focused ? '1px solid #6E4CD9' : 0,
      borderRight: focused ? '1px solid #6E4CD9' : 0,
      borderBottom: focused ? '1px solid #6E4CD9' : 0,
    }}>
      <img src={clearImg} alt="Search" style={{
        width: '20px',
        height: '20px',
        visibility: search ? 'visible' : 'hidden'
      }}/>
    </div>
  </div>
}

export default Input;
