import React, { useContext, useEffect } from 'react';
import { I18nContext } from '../../../store/I18nStore';
import ball from '../../../assets/images/ball.png';
import { useNavigate } from 'react-router-dom';

const styles = {
  text: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '-0.24px',
  },
  bg: {
    background: 'linear-gradient(180deg, #6E4CD9 0%, rgba(110, 76, 217, 0.00) 100%)',
    height: '76px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: '15px',
    whiteSpace: 'nowrap',
  },
};

function FaqHeader() {
  const navigate = useNavigate();
  const { scheme } = useContext(I18nContext);

  useEffect(() => {
    document.body.setAttribute('background', scheme === 'dark' ? 'dark' : 'light');

    return () => {
      document.body.removeAttribute('background');
    };
  });
  const { i18n, getT, setLang, lang } = useContext(I18nContext);
  if (i18n === null) {
    return null;
  }

  const changeLang = () => {
    setLang(lang === 'en' ? 'ru' : 'en');
  };

  const toMain = () => {
    navigate('/faq/' + lang);
  };

  return (
    <div style={styles.bg as React.CSSProperties}>
      <img onClick={toMain} src={ball} alt={'Ball'} width={28} height={28} />&nbsp;
      <span onClick={toMain} style={{ color: 'white' }}>
        <span style={{ ...styles.text, fontWeight: 'bold' }}>BofP</span>&nbsp;|&nbsp;<span onClick={toMain} style={{ ...styles.text }}>{getT('header_help_center')}</span>
      </span>
      <div onClick={changeLang} style={{ position: 'absolute', right: '20px', color: 'white' }}>
        {lang === 'en' ? 'RU' : 'EN'}
      </div>
    </div>
  );
}

export default FaqHeader;
