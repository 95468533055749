import React, { useEffect } from 'react';
import phones from '../../assets/images/phones.webp';
import googleApp from '../../assets/images/googleBtn.png';
import appStore from '../../assets/images/appBtn.png';
import logo from '../../assets/images/logo.svg';

import styles from './home.module.scss';
import {Link} from "react-router-dom";

function Home() {
  useEffect(() => {
    document.body.setAttribute('background', 'home');

    return () => {
      document.body.removeAttribute('background');
    };
  });

  return (
    <section className="section">
      <div style={{position:"fixed", bottom:"50px", left:"20px"}}>
        <Link style={{color: "white"}} to={"/privacy"}>Privacy policy</Link> &nbsp;&nbsp;&nbsp;
        <Link style={{color: "white"}} to={"/terms"}>Terms and conditions</Link>
      </div>
      <div className="container">
        <div className={styles.home__content}>
          <div className={styles.home__image}>
            <img src={phones} alt="Phones image" />
          </div>
          <div className={styles.home__info}>
            <div className={styles.home__infoWrap}>
              <h1 className={styles.home__logo}>
                <img src={logo} alt="Logo" />
                <span>Battle of predictions</span>
              </h1>
              <h2 className={styles.home__title}>
                <span>Rethinking the way </span>
                <span>you compete in sports </span>
                <span>predictions with your friends</span>
              </h2>
              <p className={styles.home__descr}>Become one of our early users and battle with friends.</p>
              <div className={styles.home__wrapper}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.battleofpredictions.bofp&pli=1"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.home__link}
                >
                  <img src={googleApp} alt="Google Play" />
                </a>
                <a
                  href="https://apps.apple.com/app/battle-of-predictions/id1143412960"
                  target="_blank"
                  rel="noreferrer"
                  className={styles.home__link}
                >
                  <img src={appStore} alt="App Store" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
