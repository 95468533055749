import React, {useContext} from 'react';
import {Logo} from '../../components/shared/Logo';
import {Title} from '../../components/shared/Title';
import {TermsAbout} from '../../components/TermsAbout';
import {TermsList} from '../../components/TermsList';
import Header from "../Faq/cmp/Header";
import {I18nContext} from "../../store/I18nStore";
import TermsAboutRU from "../../components/TermsAbout/TermsAboutRU";
import TermsListRU from "../../components/TermsList/TermsListRU";

function Terms() {
  const {lang} = useContext(I18nContext);
  return (
    <>
      <Header/>
      <div style={{textAlign: 'left', padding: '15px'}}>
        <Logo/>
        {lang === 'ru'?<Title title="Условия предоставления услуг" updated="Декабрь 01, 2023"/>:<Title title="Terms of service" updated="December 01, 2023"/>}
        {lang === 'ru'?<TermsAboutRU/>:<TermsAbout/>}
        {lang === 'ru'?<TermsListRU/>:<TermsList/>}
      </div>
    </>
  );
}

export default Terms;
