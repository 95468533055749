import React, {useContext, useEffect} from 'react';
import {I18nContext} from '../../../store/I18nStore';
import back from '../../../assets/images/euro2024/chevron_left.svg';
import {useNavigate} from 'react-router-dom';
import useNavigateWithCurrentParams from "./useNavigateWithCurrentParams";

const styles = {
  text: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    letterSpacing: '-0.24px',
  },
  bg: {
    backgroundColor: 'white',
    height: '48px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    whiteSpace: 'nowrap',
    position: 'fixed',
    zIndex: 100,
    top: 0,
  },
};

function FaqHeader(props: { label: string, image?: string, onBack?: () => void }) {
  const navigate = useNavigateWithCurrentParams();
  const {i18n, getT, setLang, lang} = useContext(I18nContext);

  if (i18n === null) {
    return null;
  }

  const toMain = () => {
    navigate(-1);
  };

  return (
    <div style={styles.bg as React.CSSProperties}>
      <img onClick={props.onBack || toMain} src={back} alt={'back'}/>&nbsp;
      <div onClick={props.onBack || toMain}
           style={{color: 'black', display: 'flex', width: '100%', justifyContent: 'center', marginLeft: '-25px',}}>
        {props.image && <img src={props.image} alt={'image'}/>}
        {props.image && <>&nbsp;</>}
        <span style={{...styles.text}}>{getT(props.label)}</span>
      </div>
    </div>
  );
}

export default FaqHeader;
