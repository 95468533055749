import React from 'react';
import { Home } from '../../pages/Home';
import { Route, Routes } from 'react-router-dom';
import { ROOT } from '../../constants';
import { Terms } from '../../pages/Terms';
import { Privacy } from '../../pages/Privacy';
import { Providers } from '../../pages/Providers';
import { Faq } from '../../pages/Faq';
import { NotFound } from '../../pages/NotFound';
import FaqBuffShattering from "../../pages/Faq/FaqBuffShattering";
import FaqBuffTutorial from "../../pages/Faq/FaqBuffTutorial";
import FaqInactivity from "../../pages/Faq/FaqInactivity";
import FaqLogin from "../../pages/Faq/FaqLogin";
import FaqBuffGetting from "../../pages/Faq/FaqBuffGetting";
import FaqTennisDoubles from "../../pages/Faq/FaqTennisDoubles";
import FaqRankingAtp from "../../pages/Faq/FaqRankingAtp";
import FaqRankingWta from "../../pages/Faq/FaqRankingWta";
import FaqTournamentsExit from "../../pages/Faq/FaqTournamentsExit";
import FaqFanclubsExit from "../../pages/Faq/FaqFanclubsExit";
import FaqReleaseNotes from "../../pages/Faq/FaqReleaseNotes";
import {MainQuiz} from "../../pages/Euro2024";
import Leaderboard from "../../pages/Euro2024/Leaderboard";
import Prediction from "../../pages/Euro2024/Prediction";

function App() {
  return (
    <>
      <Routes>
        <Route path={ROOT.home} element={<Home />} />
        <Route path={ROOT.terms} element={<Terms />} />
        <Route path={ROOT.privacy} element={<Privacy />} />
        <Route path={ROOT.providers} element={<Providers />} />
        <Route path={ROOT.buff_shattering} element={<FaqBuffShattering />} />
        <Route path={ROOT.buff_tutorial} element={<FaqBuffTutorial />} />
        <Route path={ROOT.inactivity} element={<FaqInactivity />} />
        <Route path={ROOT.login} element={<FaqLogin />} />
        <Route path={ROOT.buff_getting} element={<FaqBuffGetting />} />
        <Route path={ROOT.tennis_doubles} element={<FaqTennisDoubles />} />
        <Route path={ROOT.tournaments_exit} element={<FaqTournamentsExit />} />
        <Route path={ROOT.fanclubs_exit} element={<FaqFanclubsExit />} />
        <Route path={ROOT.ranking_atp} element={<FaqRankingAtp />} />
        <Route path={ROOT.ranking_wta} element={<FaqRankingWta />} />
        <Route path={ROOT.release_notes} element={<FaqReleaseNotes />} />
        <Route path={ROOT.release_notes} element={<FaqReleaseNotes />} />
        <Route path={ROOT.euro2024Prediction} element={<Prediction />} />
        <Route path={ROOT.euro2024Leaderboard} element={<Leaderboard />} />
        <Route path={ROOT.euro2024} element={<MainQuiz />} />
        <Route path={ROOT.faq} element={<Faq />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
