import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from './termsAbout.module.scss';

function TermsAbout() {
  return (
    <div>
      <p className={styles.descr}>
        The terms of this agreement ("Terms of Service" or "Agreement") govern the relationship between You and BATTLE
        OF PREDICTIONS TECHNOLOGY INC, a company under laws of the United States of America, with registered address at:
        2546 e 17th street, 2nd floor, BROOKLYN, NY 11235 ("BATTLE OF PREDICTIONS" or "We" or "Us"), regarding Your use
        of BATTLE OF PREDICTIONS’ games, websites and related services ("Service"), including all information, texts,
        artworks, images, logos, product names, graphics, software, services and the compilation of the foregoing,
        available for Your use.
      </p>
      <p className={classNames(styles.descr, 'mb')}>
        Use of Service is also governed by BATTLE OF PREDICTIONS’ Privacy Policy which is incorporated herein by
        reference. You must agree to these Terms of Service and the Privacy Policy before accessing and using the
        Service.
      </p>
      <p className={classNames(styles.descr, 'mb')}>
        You can find the latest updated version of the Terms of Service and Privacy Policy at any time at our{' '}
        <Link to="/" className={styles.link}>
          Site→
        </Link>
        .
      </p>
      <p className={classNames(styles.descr, 'mb')}>
        BATTLE OF PREDICTIONS is a developer and publisher of games and mobile game applications (our "Games"). Our
        Games download/purchase page may contain certain additional terms, conditions and requirements, which constitute
        a part of the Terms of Service. In case you download/purchase the Apps through an online store such as Google
        Play Store, Apple App Store, etc. (“Online Store”), please, review the terms and conditions of the owner of that
        particular Online Store, which may provide for certain additional requirements applicable to the download of the
        Apps through that Online Store, its installation and use.
      </p>
      <p className={classNames(styles.descr, 'mb')}>
        We may operate sweepstakes, contests and similar promotions (collectively, “Promotions”) through the Services.
        You should carefully review the rules (e.g., the “Official Rules”) of each Promotion in which you participate
        through the Services, as they may contain additional important information about our rights to and ownership of
        the submissions you make as part of the Promotions and as a result of your participation in such Promotions. To
        the extent that the terms and conditions of such Official Rules conflict with these Terms of Service, the terms
        and conditions of such Official Rules shall govern.
      </p>
      <p className={classNames(styles.descr, 'mb')}>
        You represent that You are 16 years or older. If You are over 16 years, but younger than 18 years, You can only
        download our Games and play them on Your device, if Your parent(s) or legal guardian have/has reviewed this
        Agreement and allowed You to download and play our Game subject to this Agreement. BATTLE OF PREDICTIONS may
        require adequate proof of Your identity, age and consent from a parent or guardian at any time.
      </p>
      <p className={classNames(styles.descr, 'mb')}>
        You represent that You are accessing our Games as a private person (consumer). Service offered by BATTLE OF
        PREDICTIONS is intended solely for the purpose of entertainment. No commercial use of our Games is allowed.
      </p>
      <p className={classNames(styles.descr, 'mb')}>
        THE SERVICE IS STRICTLY FOR AMUSEMENT PURPOSES ONLY. THE SERVICE DOES NOT OFFER REAL MONEY GAMBLING OR AN
        OPPORTUNITY TO WIN REAL MONEY OR REAL WORLD PRIZES. NO ACTUAL MONEY OR ANYTHING OF VALUE CAN BE WON PLAYING THE
        GAMES OFFERED THROUGH THE SERVICE, AND NO ACTUAL MONEY IS REQUIRED TO PLAY.
      </p>
      <p className={classNames(styles.descr, 'mb-large')}>
        By using our Service, downloading any software, or browsing the website, as well as by downloading any of BATTLE
        OF PREDICTIONS’ Games from Online Stores, You accept the present Terms of Service. If You do not agree to the
        Terms of Service and/or Privacy Policy, You may not use or otherwise access Service. Use of the Services is void
        where prohibited.
      </p>
    </div>
  );
}

export default TermsAbout;
