import React, {useContext} from 'react';
import { IBSectionProps, IBTextProps } from './types';
import { I18nContext } from '../../store/I18nStore';
import Header from "./cmp/Header";




function FaqRankingWta() {
  const { i18n, getT } = useContext(I18nContext);

  function BText(props: IBTextProps) {
    return (
      <>
        <div
          style={{
            fontFamily: 'Inter',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '16px',
            letterSpacing: '-0.24px',
          }}
          dangerouslySetInnerHTML={{ __html: getT(props.t).replaceAll('\n', '<br>') }}
        />
      </>
    );
  }


  function BSection(props: IBSectionProps) {
    const style = {
      fontFamily: 'Inter',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.24px',
    };

    if (props.big) {
      style.fontSize = '20px';
    }

    return (
      <div style={style} id={props.t}>
        {getT(props.t)}
      </div>
    );
  }

  if (i18n === null) {
    return null;
  }

  return (
    <>
      <Header />
      <div style={{ textAlign: 'left', padding: '15px' }}>
        <BSection t={'page_title_tennis_ranking_wta'} big={true} />
        <br/>
        <BText t={'page_description_tennis_ranking_wta'} />
      </div>
    </>
  );
}

export default FaqRankingWta;
