import React from 'react';


function MainImage(props:{image: string, children: React.ReactNode, imageStyle?: React.CSSProperties}) {
  const styles: { [key: string]: React.CSSProperties } = {
    bg: {
      marginTop: '48px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      width: '100%',
      minHeight: '290px',
      //background: `url(${props.image}) lightgray 50% / cover no-repeat`,
      alignSelf: 'stretch',
      ...props.imageStyle || {},
    },
  };

  return (
    <div style={styles.bg}>
      <img src={props.image}/>
      <div>
        {props.children}
      </div>
    </div>
  );
}

export default MainImage;
