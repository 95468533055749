import React from 'react';
import styles from './title.module.scss';

interface ITitleProps {
  title: string;
  updated: string;
}

function Title({ title, updated }: ITitleProps) {
  return (
    <>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.subtitle}>Last updated: {updated}</p>
    </>
  );
}

export default Title;
