import React, {Fragment, useContext, useEffect, useMemo, useRef} from 'react';
import Header from './cmp/Header';
import {I18nContext} from '../../store/I18nStore';
import MainImage from "./cmp/MainImage";
import Question from "./cmp/Question";
import useAsyncEffect from "use-async-effect";
import {API_URL, UserProfile} from "./types";
import prizeEn from '../../assets/images/euro2024/prize_en.png';
import prizeRu from '../../assets/images/euro2024/prize_ru.png';
import prizeUk from '../../assets/images/euro2024/prize_uk.png';
import {ROOT} from "../../constants";
import mainImage from '../../assets/images/euro2024/main.png';
import skeletonMainImage from '../../assets/images/euro2024/skeletonMainImage.svg';
import bottomIcon from '../../assets/images/euro2024/bottom.png';
import Skeleton from "./cmp/Skeleton";
import usePreloadImage from "./cmp/usePreloadImage";
import useNavigateWithCurrentParams from "./cmp/useNavigateWithCurrentParams";
import {genHeader} from "./cmp/genHeader";

function MainQuiz() {
  const styles: { [key: string]: React.CSSProperties } = {
    textH2: {
      color: '#202135',
      textAlign: 'center',
      fontSize: '38px',
      fontWeight: 700,
      lineHeight: '48px', // 120%
      textTransform: 'uppercase',
      fontFamily: 'DrukCyr-MediumItalic',
    },
    textH1: {
      color: '#202135',
      textAlign: 'center',
      fontSize: '60px',
      fontWeight: 700,
      lineHeight: '72px', // 120%
      textTransform: 'uppercase',
      fontFamily: 'DrukCyr-MediumItalic',
    },

    prizeText1: {
      fontSize: '36px',
      fontWeight: 'bold',
      color: '#FFF',
      textShadow: '2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000',
      WebkitTextStroke: '2px #F6F6F7',
    },
    prizeText2: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#FFF',
      textShadow: '2px 2px 0 #000, -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000',
    },
  };


  const navigate = useNavigateWithCurrentParams();
  const {i18n, getT, scheme, accessToken, lang} = useContext(I18nContext);
  const [data, setData] = React.useState<UserProfile | null>(null);
  const [error, setError] = React.useState<{ error: string } | null>(null);

  useEffect(() => {
    document.body.style.backgroundColor = '#F6F6F7';
  }, []);


  const mainImageLoaded = usePreloadImage(mainImage);

  useAsyncEffect(async () => {
    const url = `${API_URL}/v2/euro/sets`;
    const response = await fetch(url, {
      method: 'GET',
      headers: genHeader(accessToken, url),
    });
    const data: UserProfile | { error: string } = await response.json();
    if (!response.ok) {
      setError(data as { error: string });
    } else {
      console.log(data);
      setTimeout(() => {
        setData(data as UserProfile)
        setError(null)
      }, 0);
    }
  }, []);

  if (i18n === null) {
    return null;
  }

  return (
    <>
      <Header label={'euro_challenger_title'} onBack={() => {
        console.log(123);
        window.location.href = "https://localhost?exit=true"
      }}/>
      {error && <div
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>{error.error}</div>}
      {(data && mainImageLoaded) ? <><MainImage image={mainImage}>
      </MainImage>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: "center"}}>
          <div style={styles.textH2}>{getT('euro_challenger_info')}&nbsp;</div>
          <div style={styles.textH2}>{getT('euro_challenger_info_1')}</div>
        </div>
        <div style={{
          display: 'flex',
          padding: '15px',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
        }}>
          {data?.leaderboard?.position && <>
            <Question profile={data || undefined} onClick={() => navigate(ROOT.euro2024Leaderboard)}/>
            <div style={{height: '24px', width: '28px'}}/>
          </>}
          {data?.euroQuestionsSets.map((question, index) => (
            <Fragment key={index}>
              <Question question={question}/>
              {index + 1 < data?.euroQuestionsSets.length &&
                <div style={{height: '24px', width: '28px', borderRight: '4px dotted #DBDBDE'}}/>}
            </Fragment>
          ))}
          {lang === 'ru' && <img src={prizeRu} alt="prize" style={{width: '100%', height: 'auto'}}/>}
          {lang === 'uk' && <img src={prizeUk} alt="prize" style={{width: '100%', height: 'auto'}}/>}
          {(lang !== 'uk' && lang !== 'ru') && <img src={prizeEn} alt="prize" style={{width: '100%', height: 'auto'}}/>}
        </div>
      </> : <div
        style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingTop: '48px'}}>
        <Skeleton style={{marginBottom: '10px', width: '100%', minHeight: '340px'}} image={skeletonMainImage}/>
        <Skeleton width="200px" height="40px" style={{marginBottom: '10px'}}/>
        <Skeleton width="160px" height="30px" style={{marginBottom: '10px'}}/>
        <Skeleton width="350px" height="50px" style={{marginBottom: '30px'}}/>
        <Skeleton width="350px" height="50px" style={{marginBottom: '10px'}}/>
        <Skeleton width="350px" height="50px" style={{marginBottom: '10px'}}/>
      </div>}
      <div id='goDown' style={{
        position: 'fixed',
        width: '100%',
        height: '68px',
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: '0px',
        background: 'linear-gradient(180deg, transparent 20%, #744AE1 120%)'
      }}>
        <div style={{
          display: "flex",
          backgroundColor: '#6E4CD9',
          color: 'white',
          borderRadius: '12px',
          justifyContent: 'center',
          alignItems: 'center',
          width: '158px',
          fontSize: '20px',
          fontWeight: '700',
          lineHeight: '24px',
          fontFamily: 'DrukCyr-MediumItalic',
          textTransform: 'uppercase'
        }}>
          <img src={bottomIcon}/>&nbsp;{getT('euro_button_predict')}&nbsp;<img src={bottomIcon}/>
        </div>
      </div>
    </>
  );
}

export default MainQuiz;
