import React, {Fragment, useContext, useEffect, useMemo, useRef} from 'react';
import Header from './cmp/Header';
import {I18nContext} from '../../store/I18nStore';
import useAsyncEffect from "use-async-effect";
import {API_URL, LeaderboardData} from "./types";
import leaderboard from '../../assets/images/euro2024/leaderboard.svg';
import Skeleton from "./cmp/Skeleton";
import useOnScreen from "./cmp/useOnScreen";
import {genHeader} from "./cmp/genHeader";
import Input from "./cmp/Input";

let timeout: NodeJS.Timeout | undefined = undefined;

function Leaderboard() {
  const {i18n, getT, scheme, accessToken} = useContext(I18nContext);
  const [data, setData] = React.useState<LeaderboardData | null>(null);
  const [search, setSearch] = React.useState<string>('');
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [offset, setOffset] = React.useState<number>(0)
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = (event: any) => {
      const element = divRef.current?.parentNode?.parentNode?.parentNode as HTMLDivElement;
      if (element) {
        const scrollTop = element.scrollTop;
        const scrollHeight = element.scrollHeight;
        const clientHeight = element.clientHeight;

        if (scrollTop + clientHeight + 100 >= scrollHeight) {
          //console.log('Scrolled to the bottom');
          setOffset(data?.leaderboard?.length || 0);
        }
      }
    };


    const div = document;
    if (div) {
      div.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (div) {
        div.removeEventListener('scroll', handleScroll);
      }
    };
  }, [data]);

  const styles = {
    header: {
      fontFamily: 'Inter',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: '600',
      lineHeight: '12px',
      letterSpacing: '-0.24px',
    },
    tr: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '-0.24px',
    },
    me: {
      borderRadius: '8px',
      //border: '1px solid #8B69E3',
      border: '1px solid color(display-p3 0.5255 0.4157 0.8627)',
      background: '#EEEAFA'
    }

  };
  const ref = useRef<HTMLDivElement>(null);
  const meOnScreen = useOnScreen(ref, '0px', true);
  console.log(meOnScreen);

  useEffect(() => {
    document.body.style.backgroundColor = 'white';
    return () => {
      document.body.style.backgroundColor = '#F6F6F7';
    };
  }, []);

  const ifMeExists = useMemo(() => {
    const leaderboard = data?.leaderboard;
    const meUserId = data?.me?.user?.id;

    if (!leaderboard || !meUserId) {
      return false;
    }

    return leaderboard.filter((item) => item.user.id === meUserId).length > 0;
  }, [data]);

  useAsyncEffect(async () => {
    const url = `${API_URL}/v2/euro/leaderboard?search=${search}&offset=${offset}&limit=50`;
    const response = await fetch(url, {
      method: 'GET',
      headers: genHeader(accessToken, url),
    });
    const result = await response.json();
    if (!data || offset === 0) {
      setData(result);
    } else {
      data.leaderboard = data.leaderboard.concat(result.leaderboard);
      data.me = result.me;
      setData({...data})
    }
  }, [search, offset]);

  useAsyncEffect(async () => {
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
     setOffset(0);
     setSearch(searchValue);
    }, 300);
  }, [searchValue]);

  if (i18n === null) {
    return null;
  }


  return (
    <>
      <Header label={'euro_challenger_leaderboard_title'} image={leaderboard}/>
      <div style={{
        backgroundColor: '#F6F6F7',
        position: 'fixed',
        width: '100%',
        top: '48px',
        zIndex: 1000
      }}>
        <div style={{
          paddingRight: '10px',
          paddingLeft: '10px',
          paddingTop: '8px',
          paddingBottom: '8px',
          display: 'flex',
          alignItems: 'center',
        }}>
          <Input onChange={(v) => setSearchValue(v)} placeholder={getT('euro_question_list_search_placeholder')}/>
        </div>
        <div style={{
          ...styles.header,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '2px solid #E4E4E7',
          paddingTop: '8px',
          paddingBottom: '8px',
        }}>
          <div style={{
            paddingLeft: '22px',
          }}>
            <span style={{display: 'inline-block', width: '20px'}}>{getT('euro_position')}&nbsp;&nbsp;</span>
            <span>{getT('euro_player')}</span>
          </div>
          <div style={{
            paddingRight: '15px',
          }}>
            <span>{getT('euro_points_simple')}&nbsp;</span>
          </div>
        </div>
      </div>


      <div
        ref={divRef}
        style={{
          display: 'flex',
          padding: '5px',
          paddingRight: '10px',
          paddingLeft: '10px',
          paddingTop: '165px',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
          overflowY: 'auto',
          height: '100%'
        }}>
        {data?.leaderboard?.length === 0 && <div style={{
          ...styles.tr,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '5px',
          paddingTop: '5px',
          paddingRight: '10px',
          paddingLeft: '10px',
        }}>
          <span>{getT('euro_search_no_result')}</span>
        </div>}

        {data ? <>
            {data?.leaderboard?.map((item, index) => (
              <div key={index} ref={data.me?.user?.id === item.user.id ? ref : null} style={{
                ...styles.tr,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                paddingBottom: '5px',
                paddingTop: '5px',
                paddingRight: '10px',
                paddingLeft: '10px',
                boxSizing: 'border-box',
                ...(item.user.id === data?.me?.user.id ? styles.me : {})
              }}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <span style={{display: 'inline-block'}}>{item.position}</span>
                  <span>&nbsp;{item.user.photo ? <img style={{width: 32, height: 32, borderRadius: 12}}
                                                      src={item.user.photo}
                                                      onError={(e) => (e.target as HTMLImageElement).src = 'https://graph.facebook.com/v2.6/106714651287152/picture?type=large'}
                    /> :
                    <div style={{width: 32, height: 32}}></div>}</span>
                  <span>&nbsp;{item.user.profileName}</span>
                </div>
                <div style={{display: "flex", alignItems: "center"}}>
                  <span>{item.points}</span>
                </div>
              </div>
            ))}
          </>
          : <div
            style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', paddingTop: '48px'}}>
            <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
            <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
            <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
            <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
            <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
            <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
            <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
            <Skeleton width="350px" height="30px" style={{marginTop: '15px'}}/>
          </div>}

        {((!ifMeExists || !meOnScreen) && data?.me) && <div style={{
          ...styles.tr,
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          position: 'fixed',
          bottom: '30px',
          paddingRight: '10px',
          paddingLeft: '10px',
          boxSizing: 'border-box',
          left: '0',
          right: '0',
        }}>
          <div style={{
            ...styles.tr,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            paddingBottom: '5px',
            paddingTop: '5px',
            paddingRight: '10px',
            paddingLeft: '10px',
            boxSizing: 'border-box',
            left: '0',
            right: '0',
            ...styles.me
          }}>
            <div style={{display: "flex", alignItems: "center"}}>
              <span style={{display: 'inline-block'}}>{data?.me.position}</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;<img style={{width: 32, height: 32, borderRadius: 12}}
                                                 src={data?.me.user.photo}/></span>
              <span>&nbsp;{data?.me.user.profileName}</span>
            </div>
            <div style={{display: "flex", alignItems: "center"}}>
              <span>{data?.me.points}</span>
            </div>
          </div>
        </div>
        }
      </div>

    </>
  );
}

export default Leaderboard;
