import React from 'react';
import classNames from 'classnames';

import style from './termsList.module.scss';

function TermsList() {
  return (
    <div>
      <ol>
        <li className="mb-large">
          <span className={style.title}>1. End-User License Agreement</span>
          <ol>
            <li className="mb">
              <span className={style.subtitle}>1.1. License</span>
              <p className={classNames(style.info, 'mb')}>
                Subject to the terms of this Agreement, BATTLE OF PREDICTIONS grants You a non-exclusive,
                non-transferable, non-sublicensable, revocable, limited-right license subject to the limitations below
                to access and use copyright or otherwise legally protected materials provided as part of the Service for
                Your personal non-commercial entertainment purpose.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>1.2. Provision of online services</span>
              <p className={classNames(style.info, 'mb')}>
                BATTLE OF PREDICTIONS may provide certain online services as part of the Services in accordance with
                these Terms of Service. BATTLE OF PREDICTIONS has the right to limit or discontinue these online
                services at any time unless You have bought a Subscription under these Terms of Service in which case
                BATTLE OF PREDICTIONS will provide the online services in accordance with such Subscription.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>1.3. Certain Restrictions</span>
              <p className={style.info}>
                The rights granted to You in this Agreement are subject to the following restrictions:
              </p>
              <p className={style.info}>
                (a) You shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise
                commercially exploit Service; (b) You shall not modify, translate, adapt, merge, make derivative works
                of, disassemble, decompile, reverse compile or reverse engineer any part of Service, except to the
                extent the foregoing restrictions are expressly prohibited by applicable law; (c) You shall not access
                Service in order to build a similar or competitive service or application; (d) except as expressly
                stated herein, no part of Service may be copied, reproduced, distributed, republished, downloaded,
                displayed, posted or transmitted in any form or by any means, or (e) You shall not remove or destroy any
                copyright notices or other proprietary markings contained on or in Service. Any future release, update,
                or other addition to functionality of Service (including in-app purchases in Games: Virtual Items and
                Virtual Currency, additional levels, and gameplay enhancements) shall be subject to the terms of this
                Agreement, unless otherwise provided in terms associated with such addition. All copyright and other
                proprietary notices on any Game content must be retained on any copies.
              </p>
            </li>
          </ol>
        </li>
        <li className="mb-large">
          <span className={style.title}>2. Ownership</span>
          <p className={classNames(style.info, 'mb')}>
            The copyright or otherwise legally protected materials provided as part of the Service provided to You are
            licensed to You and not sold.
          </p>
          <p className={style.info}>
            While certain Applications or Games may allow you to “buy”, “purchase”, “own” or “earn” certain Virtual
            Currency or Items, you do not, in fact, own any component of the Services, nor will you receive any real
            currency, prizes or any equivalent thereof. You do not in fact “own” the Virtual Items and the amounts of
            any Virtual Item do not refer to any credit balance of real currency or its equivalent. BATTLE OF
            PREDICTIONS prohibits and does not recognize any purported transfers of Virtual Currency or Items
            effectuated outside of the Services, unless expressly agreed to by BATTLE OF PREDICTIONS in writing in
            advance. Any “Virtual Currency” balance shown in your Account does not constitute a real-world balance or
            reflect any stored value. Accordingly, you may not attempt to sell or exchange Virtual Currency or Items for
            “real world” currency or any item of value of any kind without BATTLE OF PREDICTIONS express, written, prior
            approval, and such Virtual Currency and Items do not have “real world” cash value or stored value. Any such
            transfer or attempted transfer is void and prohibited and may subject your Account to termination.
          </p>
          <p className={style.info}>
            BATTLE OF PREDICTIONS (and its licensors, where applicable) own all rights, title and interest, including
            without limitations any Games, titles, computer code, themes, objects, catch phrases, concepts, artwork,
            animations, sounds, musical compositions, audio-visual effects, method of operation, documentation,
            accounts, Virtual Currency and Virtual Items, and material produced by BATTLE OF PREDICTIONS and/or received
            or made available while playing the Games or developed during the course of the Games, and all related
            intellectual property rights, in and to Service.
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>3. Copyright</span>
          <p className={style.info}>
            Copyright, trademarks, and all other proprietary rights shown in Service (including, but not limited to,
            software, services, text, graphics, and logos) are reserved to BATTLE OF PREDICTIONS. You agree not to copy,
            republish, modify, download, distribute, license, sublicense, reverse engineer, or create derivatives based
            on the Service except as expressly authorized herein. Except as otherwise provided, the content published on
            the website and/or in the Game or in the app stores may be reproduced or distributed in unmodified form for
            personal, non-commercial use only. Any other use of the content, including, without limitation,
            distribution, reproduction, modification, display, or transmission, without the prior written consent of
            BATTLE OF PREDICTIONS is strictly prohibited. All copyright and other proprietary notices shall be retained
            on all reproductions.
          </p>
          <p className={style.info}>
            BATTLE OF PREDICTIONS is not affiliated in any way with professional sports leagues, professional sports
            teams, athletics organizations, non-profit athletic organizations, sports federations or the sports
            governing bodies. Product names, logos, brands or other trademarks displayed or referred to within Services
            are the property of their respective owners and do not represent any affiliation, sponsorship or otherwise
            with BATTLE OF PREDICTIONS, unless specifically provided otherwise.
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>4. In-Game Currencies and Goods</span>
          <p className={classNames(style.info, 'mb')}>
            BATTLE OF PREDICTIONS’ Games include virtual in-game currency («Virtual Currency»), such as coins, cash, or
            similar, that can be purchased for real money and in turn used to play Game or to purchase virtual in-game
            items ("Virtual Items»).
          </p>
          <p className={style.info}>
            Please note that you only purchase a limited, revocable, non-transferable license to use in-game Virtual
            Currency or Virtual Items, and you agree that you do not own them.
          </p>
          <p className={classNames(style.info, 'mb')}>
            All Virtual Currency and Virtual Items transactions are handled by third-party e-commerce payment providers.
            By purchasing in-game Virtual Currency and Virtual Items, You are bound by and agree to the third-party
            payment providers’ Terms of Use.
          </p>
          <p className={classNames(style.info, 'mb')}>
            In no way can Virtual Currency or Virtual Items be exchanged with us or anyone else for real money
            (traditional currency, any other open digital currency), goods, other items, or services of monetary value.
            The transfer of Virtual Currency or Virtual Items is prohibited except where expressly authorized in
            Service. Other than as expressly authorized in Service, You shall not sell, redeem or otherwise transfer
            Virtual Currency or Virtual Items to any person or entity, including but not limited to BATTLE OF
            PREDICTIONS, another user or any third party. In the event your account is limited, terminated, suspended,
            modified, or deleted for any reason, at BATTLE OF PREDICTIONS’ sole and absolute discretion for any reason
            attributable to You, or if BATTLE OF PREDICTIONS discontinues its Games, or if Virtual Items of the User are
            lost, hacked or stolen, you forfeit any and all Virtual Currency and Virtual Items earned or purchased.
          </p>
          <p className={style.info}>Price and availability of Virtual Items are subject to change without notice.</p>
          <p className={style.info}>
            BATTLE OF PREDICTIONS may manage, regulate, control, modify or eliminate available Virtual Items at any
            time, with or without notice. BATTLE OF PREDICTIONS may also revise the pricing for the goods and services
            offered through the Service at any time. BATTLE OF PREDICTIONS shall have no liability to You or any third
            party in the event that BATTLE OF PREDICTIONS exercises any such rights.
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>5. Third Parties</span>
          <ol>
            <li className="mb">
              <span className={style.subtitle}>5.1. Third-Party Services</span>
              <p className={style.info}>
                When using the Services third parties may offer you certain third-party applications (like leaderboards,
                game networks) to provide content through the Game («Third Party Services»). Upon your acceptance of
                this offer BATTLE OF PREDICTIONS may then permit such Third Party Services. The Game may be used to send
                content provided by the Third-Party Service between users who have the Third-Party Service installed on
                their device. When You do so, BATTLE OF PREDICTIONS will share information with the Third-Party Service
                as described in the BATTLE OF PREDICTIONS’ Privacy Policy to the extent necessary for the third party to
                provide the Third-Party Services. BATTLE OF PREDICTIONS is not responsible for and does not control
                Third-Party Services. BATTLE OF PREDICTIONS supports these Third-Party Services only as far as you
                ordered them from the third party. BATTLE OF PREDICTIONS has no obligation to review or monitor and does
                not approve, endorse, or make any representations or warranties with respect to Third-Party Services.
                You use all Third-Party Services at Your own risk. When You access a Third-Party Service, the applicable
                third party’s terms and policies apply, including the third party’s privacy policies. You should make
                whatever investigation You feel necessary or appropriate before proceeding with any transaction in
                connection with any Third-Party Service.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>5.2. Links to Third Parties</span>
              <p className={classNames(style.info, 'mb')}>
                Our Services may feature advertisements from third party companies. Please review our Privacy Policy
                which explains what information we share with advertisers. We are not responsible for the availability
                of such websites or resources of the third parties, and we are not responsible or liable for any
                content, advertising, or services they provide.
              </p>
              <p className={style.info}>
                Any content, advertising or services by such third party are provided following the terms of services
                and privacy policies to be found on the website of the respective third party and, where applicable, you
                must familiarize yourself and accept the terms of services and privacy policies of such third party
                before using their services. Any separate charges or obligations you incur in your dealings with these
                third parties are your responsibility. BATTLE OF PREDICTIONS is not liable for any claim relating to any
                content, goods or services of third parties.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>5.3. Other users</span>
              <p className={style.info}>
                A Game may contain User Generated Content (UGC) provided by other users of the Game. BATTLE OF
                PREDICTIONS is not responsible for and does not control the UGC. BATTLE OF PREDICTIONS has no obligation
                to review or monitor, and does not approve, endorse, or make any representations or warranties with
                respect to UGC. You use all UGC and interact with other users at Your own risk. Your interactions with
                other users are solely between You and the other user and we are under no obligation to become involved.
                You agree that BATTLE OF PREDICTIONS will not be responsible for any liability incurred as the result of
                any such interactions.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>5.4. Release</span>
              <p className={style.info}>
                You hereby irrevocably and unconditionally release and forever discharge BATTLE OF PREDICTIONS (and its
                suppliers) from any and all claims, demands, and rights of action, whether now known or unknown, which
                relates to any interactions with, or act or omission of, any Third Party Service, other Games users, or
                Third Party Advertisers. IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE
                SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: «A GENERAL RELEASE DOES NOT EXTEND TO
                CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING
                THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
                DEBTOR.»
              </p>
            </li>
          </ol>
        </li>
        <li className="mb-large">
          <span className={style.title}>6. Payments and Refunds</span>
          <ol>
            <li className="mb">
              <span className={style.subtitle}>6.1. General provisions</span>
              <p className={style.info}>
                When you purchase the Apps from the Online Store, including subscriptions and in-app purchases (Virtual
                Items and Virtual Currency), the payment for such purchases may be processed either by third parties on
                our behalf, or directly by the owner of the Online Store. When purchasing the Apps, including
                subscriptions and in-app purchases, you agree that the digital content will be available to you
                immediately, and acknowledge that you will lose your rights to the 14 days cancellation period and
                refunds available for EU and EEA residents. If you download the Apps from the Online Store owner, before
                effecting the purchase from the Online Store, please also review and accept the terms and conditions of
                the Online Store owner with regard to your rights to cancel orders and get related refunds. Thus, if You
                have any payment related issues, then You need to contact App Store or Google Play directly.
              </p>
              <p className={style.info}>
                All purchases and redemptions of Virtual Items and Virtual Currency, as well as payments for the
                Subscriptions as it is described in section 6.2, made through Service are final and non-refundable,
                except when required by law. You agree that BATTLE OF PREDICTIONS is not required to provide a refund
                for Virtual Items and Virtual Currency for any reason, and that You will not receive money or other
                compensation for unused Virtual Items and Virtual Currency, whether You lost license under this
                Agreement voluntarily or involuntarily.
              </p>
              <p className={style.info}>
                Please note that if You request Your personal data to be erased (deleted) as specified in BATTLE OF
                PREDICTIONS ´ Privacy Policy, You will permanently and without a right to a refund lose all Your Virtual
                Items and Virtual Currency as BATTLE OF PREDICTIONS can no longer associate such Virtual Items and
                Virtual Currency with You.
              </p>
              <p className={style.info}>
                YOU ACKNOWLEDGE THAT BATTLE OF PREDICTIONS IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON, AND THAT
                YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL CURRENCY AND/OR VIRTUAL ITEMS WHEN
                YOU DELETE YOUR ACCOUNT OR WHEN YOU CEASE TO USE THE GAME.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>6.2. Subscriptions</span>
              <p className={classNames(style.info, 'mb')}>
                Some parts of the Service are billed on a subscription basis («Subscription(s)»). Subscription provides
                access to dynamic content or services in the Game on an ongoing basis. Subscriptions may be available at
                different fees chargeable for a set period of time specified in the Game («Subscription Period»).
                Payments for such subscriptions would be charged at your Apple App Store or Google Play account
                («Account») when You confirm the Subscription by available confirmation tools of the Game. You
                acknowledge and agree that all billing and transaction processes are handled by App Store or Google
                Play, from which platform You downloaded the App, and are governed by their terms and conditions. If You
                have any payment related issues, then You need to contact App Store or Google Play directly.
              </p>
              <p className={classNames(style.info, 'mb')}>
                Trial Subscription is offered free of charge for certain period of time from activation specified in the
                relevant offer in the Game. If You do not cancel the Trial Subscription within such period, Subscription
                Fee shall be withdrawn from Your Account when the trial period expires. Please note that Your
                Subscription begins immediately after the activation of a trial Subscription, not after the seven-day
                trial period. You may cancel a subscription during its free trial period using the Subscription setting
                of Your Account. This must be done 24 hours before the end of the free trial subscription period (Apple
                App Store), or at any time before the end of the free trial subscription period (Google Play Store),
                otherwise it will be renewed as a paid subscription. BATTLE OF PREDICTIONS cannot cancel Your free-trial
                subscription if it has already been activated.
              </p>
              <p className={style.info}>
                Subscription is automatically renewable for the same price and duration period as the original
                subscription package chosen by You, unless You turn off it: (i) in case of an Apple App Store, at least
                24-hours before the end of the current subscription period, or (ii) in case of Google Play Store, at any
                time before the end of the subscription period. Subscription Fee for renewal shall be taken from Your
                Account within 24-hours prior to the end of the current subscription period (Apple App Store), or at the
                end of the subscription period (Google Play Store) at the cost of the chosen package. In case
                Subscription Fee cannot be taken from Your Account due to absence of monetary funds, invalidity of
                credit card or for any other reasons, Your Subscription is automatically cancelled.
              </p>
              <p className={style.info}>
                Once You have bought a Subscription, You can manage it and switch off automatic renewal at any time
                after the purchase in Your Account setting. If Subscription has already been activated, You can cancel
                the Subscription at any time and the cancellation will take effect after the end of the last day of that
                subscription period.
              </p>
              <p className={style.info}>Except when required by law, paid Subscription Fees are non-refundable.</p>
              <p className={style.info}>
                BATTLE OF PREDICTIONS in its sole discretion and at any time may modify the Subscription Fee. Any
                Subscription Fee change will become effective at the end of the current Subscription period. You will be
                provided a reasonable prior notice of any change in Subscription Fee. If You do not take action to agree
                to the increase in Subscription Fee, Your Subscription shall expire at the end of the current
                Subscription period.
              </p>
            </li>
          </ol>
        </li>
        <li className="mb-large">
          <span className={style.title}>7. Warranties and Disclaimers</span>
          <ol>
            <li className="mb">
              <span className={style.subtitle}>7.1. </span>
              <p className={style.info}>
                ALL INFORMATION, SOFTWARE, AND SERVICE OFFERED BY BATTLE OF PREDICTIONS ARE PROVIDED ON AN "AS IS" BASIS
                WITHOUT WARRANTIES OF ANY KIND EXPRESSED OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT, AND THOSE ARISING FROM COURSE OF
                DEALING OR USAGE OF TRADE.
              </p>
              <p className={classNames(style.info, 'mb')}>
                BATTLE OF PREDICTIONS DOES NOT WARRANT THAT YOU WILL BE ABLE TO ACCESS OR USE THE SERVICE AT THE TIMES
                AND LOCATIONS OF YOUR CHOOSING; THAT SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS WILL BE
                CORRECTED; OT THAT THE GAMES OR SERVICE WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
              </p>
              <p className={style.info}>
                BATTLE OF PREDICTIONS ASSUMES NO RESPONSIBILITY FOR ERRORS OR OMISSIONS IN THE INFORMATION, SOFTWARE, OR
                SERVICES REFERENCED OR LINKED TO ON THIS WEBSITE.
              </p>
              <p className={classNames(style.info, 'mb')}>
                IN NO CASE SHALL BATTLE OF PREDICTIONS BE HELD LIABLE FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, PUNITIVE,
                SPECIAL, OR INCIDENTAL DAMAGES (INCLUDING, BUT NOT LIMITED TO, THOSE RESULTING FROM A LOSS OF BUSINESS,
                DATA, OR REVENUE; RELIANCE ON THE MATERIALS PRESENTED; DELAYS; OR BUSINESS INTERRUPTIONS ARISING OUT OF
                OR IN CONNECTION WITH THE USE OR PERFORMANCE OF BATTLE OF PREDICTIONS INFORMATION) REGARDLESS OF WHETHER
                BATTLE OF PREDICTIONS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR NOT.
              </p>
              <p className={style.info}>
                DOWNLOADING AND USING ANY OF THE SOFTWARE OR SERVICE OFFERED BY BATTLE OF PREDICTIONS IS DONE AT YOUR
                OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ALL DAMAGE TO YOUR DEVICE SYSTEM, LOSS OF
                DATA, OR OTHER HARM THAT RESULTS FROM SUCH ACTIVITIES.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>7.2. </span>
              <p className={style.info}>
                SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR PROVIDE SPECIAL CONDITIONS, SO
                THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>7.3. </span>
              <p className={style.info}>
                You represent and warrant that (i) You are not located in a country that is subject to a U.S. Government
                embargo, or that have been designated by the U.S. Government as a «terrorist supporting» country; and
                (ii) You are not listed on any U.S. Government list of prohibited or restricted parties; and (iii) You
                are solely responsible for compliance with all applicable laws, including without limitation export and
                import regulation.
              </p>
            </li>
          </ol>
        </li>
        <li className="mb-large">
          <span className={style.title}>8. Limitation of Liability</span>
          <ol>
            <li className="mb">
              <span className={style.subtitle}>8.1. </span>
              <p className={style.info}>
                IN NO EVENT SHALL BATTLE OF PREDICTIONS (OR ITS SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
                LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING
                FROM OR RELATING TO THIS AGREEMENT OR BATTLE OF PREDICTIONS’ PRIVACY PRACTICES, ANY GAME, EVEN IF BATTLE
                OF PREDICTIONS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, ANY GAME ARE
                AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER
                SYSTEM OR MOBILE DEVICE OR LOSS OF DATA RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO THE CONTRARY
                CONTAINED HEREIN, BATTLE OF PREDICTIONS’ LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO
                THIS AGREEMENT OR BATTLE OF PREDICTIONS’ PRIVACY PRACTICES (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF
                THE FORM OF THE ACTION) WILL AT ALL TIMES BE LIMITED TO THE AMOUNT YOU’VE PAID BATTLE OF PREDICTIONS IN
                THE PRIOR 6 MONTHS (IF ANY). YOU ACKNOWLEDGE AND AGREE THAT IF YOU HAVE NOT PAID BATTLE OF PREDICTIONS
                ANY AMOUNTS IN THE PRIOR 6 MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT ANY SUCH
                CLAIM, YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE WITH BATTLE OF PREDICTIONS IS TO STOP USING THE
                SERVICES AND CANCEL YOUR ACCOUNT. IN NO EVENT SHALL BATTLE OF PREDICTIONS’ SUPPLIERS HAVE ANY LIABILITY
                ARISING OUT OF OR IN ANY WAY CONNECTED TO THIS AGREEMENT.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>8.2. </span>
              <p className={style.info}>
                SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL
                DAMAGES OR PROVIDE OTHER SPECIAL REGULATIONS, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU
                AND YOU MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY FROM JURISDICTION TO JURISDICTION.
              </p>
            </li>
          </ol>
        </li>
        <li className="mb-large">
          <span className={style.title}>9. Indemnity</span>
          <p className={classNames(style.info, 'mb')}>
            You agree to defend, indemnify and hold harmless BATTLE OF PREDICTIONS (and its suppliers) from and against
            any claims, suits, losses, damages, liabilities, costs, and expenses (including reasonable attorneys’ fees)
            brought by third parties resulting from or relating to: (i) Your use of any Game, (ii) Your UGC, or (iii)
            Your violation of this Agreement.
          </p>
          <p className={style.info}>
            BATTLE OF PREDICTIONS reserves the right, at Your expense, to assume the exclusive defense and control of
            any matter for which You are required to indemnify BATTLE OF PREDICTIONS and You agree to cooperate with our
            defense of these claims. You agree not to settle any matter without the prior written consent of BATTLE OF
            PREDICTIONS. BATTLE OF PREDICTIONS will use reasonable efforts to notify You of any such claim, action or
            proceeding upon becoming aware of it.
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>10. User Generated Content</span>
          <p className={style.info}>
            On certain areas of our Services or otherwise, you may be able to chat with other users, and submit texts,
            files, images, photos, videos, sounds, musical works, works of authorship, text postings and other materials
            and content (“User Generated Content” or “UGC”).
          </p>
          <p className={classNames(style.info, 'mb-small')}>
            Your uploading and posting of UGC is subject to the Terms of Service and the following posting rules
            (“Posting Rules”):
          </p>
          <ol>
            <li className="mb">
              <span className={classNames(style.subtitle, 'mb-small')}>A. You agree that your UGC is:</span>
              <ul>
                <li className={classNames(style.info, 'mb-small')}>Accurate;</li>
                <li className={classNames(style.info, 'mb-small')}>Not confidential;</li>
                <li className={classNames(style.info, 'mb-small')}>Not in violation of law;</li>
                <li className={classNames(style.info, 'mb-small')}>
                  Not in violation of contractual restrictions or third party rights, and that you have permission to
                  use content from any other party whose personal or other information or intellectual property is
                  contained in the UGC;
                </li>
                <li className={classNames(style.info, 'mb-small')}>
                  Not abusive, harmful, libelous, profane, obscene or otherwise objectionable;
                </li>
                <li className={classNames(style.info, 'mb-small')}>
                  Not for commercial purposes or business solicitations; and
                </li>
                <li className={classNames(style.info, 'mb-small')}>
                  Free of viruses, corrupting files, interference, cheat software, worms or other malicious code.
                </li>
              </ul>
            </li>
            <li className="mb">
              <span className={style.subtitle}>B. Responsibility of Postings.</span>
              <p className={style.info}>
                You understand and acknowledge that UGC is solely your responsibility, and that BATTLE OF PREDICTIONS is
                not responsible for the information, data, text or other materials that may appear in UGC. Opinions
                expressed in UGC do not necessarily reflect the opinions of BATTLE OF PREDICTIONS. UGC is not
                necessarily reviewed by BATTLE OF PREDICTIONS prior to posting and BATTLE OF PREDICTIONS makes no
                warranties, express or implied, as to the UGC or to the accuracy and reliability of the UGC.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>C. No Monitoring/Violation Notices.</span>
              <p className={style.info}>
                You acknowledge that BATTLE OF PREDICTIONS does not necessarily monitor any materials posted,
                transmitted, or communicated to or within the Games and other Services. Notwithstanding the foregoing,
                you further agree that BATTLE OF PREDICTIONS and its designees will have the right (but not the
                obligation) in their sole discretion to refuse, edit, move, or remove any UGC that is available via
                Games and other Services with or without notice to You.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>D. Ownership and license of UGC.</span>
              <p className={classNames(style.info, 'mb')}>
                By displaying, uploading, distributing, or otherwise using Your UGC with any Service (Game, website,
                etc.), You automatically grant, and You represent and warrant that You have the right to grant to BATTLE
                OF PREDICTIONS an irrevocable, non-exclusive, royalty-free and fully paid worldwide license with the
                right to grant sublicenses to reproduce, distribute, publicly display, publicly perform, edit, prepare
                derivative works of, incorporate into other works, and otherwise use Your UGC, solely to display Your
                UGC in any Game and Service. If You provide BATTLE OF PREDICTIONS any feedback or suggestions
                ("Feedback"), You hereby assign to BATTLE OF PREDICTIONS all rights in the Feedback and agree that
                BATTLE OF PREDICTIONS shall have the right to use such Feedback and related information in any manner it
                deems appropriate. BATTLE OF PREDICTIONS will treat any Feedback You provide to BATTLE OF PREDICTIONS as
                non-confidential and non-proprietary. You agree that You will not submit to BATTLE OF PREDICTIONS any
                information or ideas that You consider to be confidential or proprietary.
              </p>
              <p className={style.info}>
                You further acknowledge and agree that the gaming odds, sports content and sports data posted on, or
                utilized in, the Services may be established by users of the Services and are not established through
                use of a professional sports book, professional odds maker, professional sports data provider or
                professional sports content provider. As such, you acknowledge that the odds, sports content or any
                sports data utilized or posted on the Services bear no relation to “real world” sports betting odds,
                sports content or sports data. YOU UNDERSTAND AND AGREE THAT BATTLE OF PREDICTIONS MAY DELETE ALL UGC AT
                ANY TIME, AND WITHOUT NOTICE, IF BATTLE OF PREDICTIONS DEEMS THAT YOU MATERIALLY BREACH THESE POSTING
                RULES, THE TERMS OF SERVICE, APPLICABLE LAW, OR FOR ANY OTHER REASON. BATTLE OF PREDICTIONS ASSUMES NO
                LIABILITY FOR ANY INFORMATION REMOVED FROM OUR GAMES AND SERVICES AND RESERVES THE RIGHT TO PERMANENTLY
                RESTRICT ACCESS TO SERVICES.
              </p>
            </li>
          </ol>
        </li>
        <li className="mb-large">
          <span className={style.title}>11. User Conduct</span>
          <p className={style.info}>You shall accept and abide by the following:</p>
          <p className={classNames(style.info, 'mb')}>
            You agree not to use any Game to upload, distribute, or otherwise use any User Content (a) that violates any
            third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right,
            right of publicity, or any other intellectual property or proprietary right; (b) that is tortious, trade
            libelous, defamatory, false, or intentionally misleading, (c) that is harassing, abusive, threatening,
            harmful, vulgar, obscene, or offensive, hateful, sexually explicit, or racially, ethnically, or otherwise
            objectionable, or that contains pornography, nudity, or graphic or gratuitous violence, or that promotes
            violence, racism, discrimination, bigotry, hatred, or physical harm of any kind against any group or
            individuals, or is otherwise objectionable, (d) that is harmful to minors in any way; (e) that constitutes
            unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid
            schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; or (f)
            that violates of any law, regulation, or contractual obligations.
          </p>
          <p className={classNames(style.info, 'mb')}>
            You agree not to use any Game to (a) upload or distribute any computer viruses, worms, malicious code, or
            any software intended to damage or alter a computer system or data; (b) collect information or data
            regarding other users, including e-mail addresses, post and/or make available to the public any other user’s
            personal information in the Games and/or any game-related services without their consent (e.g., using
            harvesting bots, robots, spiders, or scrapers); (c) disable, overly burden, impair, or otherwise interfere
            with servers or networks connected to Games (e.g., a denial of service attack); (d) attempt to gain
            unauthorized access to the website or Game or servers or networks connected to Games (e.g., through password
            mining); or (e) interfere with another user’s use and enjoyment of any Game.
          </p>
          <p className={style.info}>
            You acknowledge and agree that BATTLE OF PREDICTIONS may use built-in tracking features to obtain
            information regarding Your use of the Games in order to improve the services we provide, and agree that such
            information is deemed to be User Content for all intents and purposes under these Terms of Service.
          </p>
          <p className={style.info}>
            You shall not (a) improperly use BATTLE OF PREDICTIONS’ support services, including without limitation
            submission of false reports of abuse or misconduct by any party; (b) disable, interfere with, or circumvent
            any security feature of the Games or any feature that restricts or enforces limitations on the use of or
            access to the Games or UGC; (c) participate in any activities and/or perform any actions that, in BATTLE OF
            PREDICTIONS’ sole opinion, lead to, result in, or may result in an authorized user of the Games being
            defrauded of Virtual Currency or Virtual Items that user has earned through authorized gameplay and/or
            purchased in the Games; (d) sell BATTLE OF PREDICTIONS’ Games or any of their parts, including but not
            limited to Virtual Currency or Virtual Items, user accounts, and/or access to user accounts to any party in
            exchange for real currency or items and/or services of monetary value; (e) engage in cheating or any other
            fraudulent activity deemed by BATTLE OF PREDICTIONS to be in conflict with the spirit of the Games; (f) use
            or take part in the use of any unauthorized third-party software designed to modify or interfere with
            Service and/or any BATTLE OF PREDICTIONS’ Games; (g) share Your password or any credentials You may use to
            access Your account with anyone; (h) purchase, sell, rent, or give away Your account, or otherwise make
            available Your account to any third party; (i) create an Account using a false identity or information, or
            on behalf of someone other than Yourself.
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>12. Modifications of this Agreement</span>
          <p className={classNames(style.info, 'mb')}>
            BATTLE OF PREDICTIONS is entitled at any time to amend or supplement these Terms of Service and any related
            provisions (e.g. BATTLE OF PREDICTIONS’ Privacy Policy). BATTLE OF PREDICTIONS shall publish any amendments
            to these Terms of Service on its website (https://battleofpredictions.com/) and may also use other means of
            notification (e.g. in-game, newsletter, pop-up). The date on which the latest update was made is indicated
            at the top of this document.
          </p>
          <p className={classNames(style.info, 'mb')}>
            Such changes will only affect the relationship with You for the future. The continued use of the Services
            will signify Your acceptance of the revised Terms of Service. If You do not wish to be bound by the changes,
            you must stop using the Services. Failure to accept the revised Terms of Service, may entitle either party
            to terminate the Agreement.
          </p>
          <p className={style.info}>
            The applicable previous version will govern the relationship prior to Your acceptance.
          </p>
          <p className={style.info}>
            We recommend that You print a copy of this Agreement for Your reference and revisit this web-page from time
            to time to ensure You are aware of any changes.
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>13. Term and Termination</span>
          <ol>
            <li className="mb">
              <span className={style.subtitle}>13.1. This Agreement runs for an indefinite term.</span>
            </li>
            <li className="mb">
              <p className={style.info}>
                <span className="fw">
                  13.2. Either Party may terminate this Agreement at any time by giving 14 days notice
                </span>{' '}
                (written or electronic communication, e.g. via e-mail, required). However, You may also terminate the
                User Agreement with immediate effect by deleting the Game from Your device.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>
                13.3. Either Party may terminate the User Agreement for cause without giving notice.
              </span>
              <p className={style.info}>
                The grounds for such termination for cause include material breach of these Terms of Service.
              </p>
              <p className={style.info}>
                If we have reasonable ground to believe that You are in material breach of these Terms of Service, we
                may suspend Your access to our Game and/or terminate this User Agreement. Your breach of Section 1, 10,
                11 shall be considered a material breach.
              </p>
            </li>
            <li className="mb">
              <p className={style.info}>
                <span className="fw">13.4. Upon expiry,</span> You will no longer be able to access the Game that was
                the subject matter of the User Agreement.
              </p>
              <p className={style.info}>
                Your in-game progress and any other Game-related data will be deleted. Only if BATTLE OF PREDICTIONS
                continues to operate the Game, You may again download the Game whereby a new User Agreement will start
                to run. However, any game-related data cannot be restored.
              </p>
            </li>
            <li className="mb">
              <p className={style.info}>
                <span className="fw">13.5. Without limiting the foregoing,</span> BATTLE OF PREDICTIONS reserves the
                right to terminate this Agreement with any user who repeatedly infringes third-party copyright rights
                upon prompt notification to BATTLE OF PREDICTIONS by the copyright owner or the copyright owner’s legal
                agent.
              </p>
            </li>
            <li className="mb">
              <p className={style.info}>
                <span className="fw">13.6. Upon termination of this Agreement, </span> Your right to use the Game will
                automatically terminate immediately without right to refund to You for Virtual Currency or Virtual Items
                acquired, developed during, or purchased in the Games. You understand that any termination may involve
                deletion of Your UGC associated therewith from our live databases. Even after this Agreement is
                terminated, the following provisions of this Agreement will remain in effect: Sections 1.3, 2, 3, 5, 6,
                7, 8, 9, 10, 13, and 19.
              </p>
            </li>
          </ol>
        </li>
        <li className="mb-large">
          <span className={style.title}>14. Notice</span>
          <p className={style.info}>
            Any notice provided to BATTLE OF PREDICTIONS pursuant to this Agreement should be sent to{' '}
            <a className={style.link} href="mailto:support@bofp.co">
              support@bofp.co
            </a>
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>15. Severability</span>
          <p className={classNames(style.info, 'mb')}>
            If any provision of this Agreement is, for any reason, held to be invalid or unenforceable, the other
            provisions of this Agreement will be unimpaired, and the invalid or unenforceable provision will be deemed
            modified so that it is valid and enforceable to the maximum extent permitted by law.
          </p>
          <p className={style.subtitle}>EU Users</p>
          <p className={classNames(style.info, 'mb')}>
            Should any provision of these Terms of Service be or be deemed void, invalid or unenforceable, the parties
            shall do their best to find, in an amicable way, an agreement on valid and enforceable provisions thereby
            substituting the void, invalid or unenforceable parts.
          </p>
          <p className={classNames(style.info, 'mb')}>
            In case of failure to do so, the void, invalid or unenforceable provisions shall be replaced by the
            applicable statutory provisions, if so permitted or stated under the applicable law.
          </p>
          <p className={classNames(style.info, 'mb')}>
            Without prejudice to the above, the nullity, invalidity or the impossibility to enforce a particular
            provision of these Terms of Service shall not nullify the entire Agreement, unless the severed provisions
            are essential to the Agreement, or of such importance that the parties would not have entered into the
            contract if they had known that the provision would not be valid, or in cases where the remaining provisions
            would translate into an unacceptable hardship on any of the parties.
          </p>
          <p className={style.subtitle}>US Users</p>
          <p className={style.info}>
            Any such invalid or unenforceable provision will be interpreted, construed and reformed to the extent
            reasonably required to render it valid, enforceable and consistent with its original intent. These Terms of
            Service constitute the entire Agreement between Users and BATTLE OF PREDICTIONS (the Owner of the Services)
            with respect to the subject matter hereof, and supersede all other communications, including but not limited
            to all prior agreements, between the parties with respect to such subject matter. These Terms of Service
            will be enforced to the fullest extent permitted by law.
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>16. Entire Agreement</span>
          <p className={style.info}>
            This Agreement is the final, complete and exclusive agreement between You and BATTLE OF PREDICTIONS with
            respect to the subject matters hereof (including all Games) and supersedes and merges all prior discussions
            and agreements between the parties with respect to such subject matters (including any prior End-User
            License Agreements and Terms of Service or Privacy Policy). Our failure to exercise or enforce any right or
            provision of this Agreement shall not operate as a waiver of such right or provision.
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>17. Interpretation</span>
          <p className={style.info}>
            The section titles in this Agreement are for convenience only and have no legal or contractual effect. The
            word "including" means "including without limitation". Your relationship to BATTLE OF PREDICTIONS is that of
            an independent contractor, and neither party is an agent or partner of the other.
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>18. Assignment</span>
          <p className={style.info}>
            BATTLE OF PREDICTIONS may assign or delegate these Terms of Service and/or the BATTLE OF PREDICTIONS’
            Privacy Policy, in whole or in part, to any person or entity at any time with or without Your consent but
            taking into account Yours legitimate interests. Provisions about changes of these Terms of Service will
            apply accordingly. The terms of this Agreement shall be binding upon assignees. You may not assign or
            delegate any rights or obligations under the Terms of Service or Privacy Policy without BATTLE OF
            PREDICTIONS’ prior written consent, and any attempted assignment in violation of the foregoing will be null
            and void.
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>19. Supplemental Policies</span>
          <p className={style.info}>
            BATTLE OF PREDICTIONS may publish additional policies related to specific services such as forums, contests
            or loyalty programs. Your right to use such services is subject to those specific policies and these Terms
            of Service.
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>20. No waiver</span>
          <p className={style.info}>
            The BATTLE OF PREDICTIONS’ failure to assert any right or provision under these Terms of Service shall not
            constitute a waiver of any such right or provision. No waiver shall be considered a further or continuing
            waiver of such term or any other term.
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>21. Applicable Law</span>
          <p className={classNames(style.info, 'mb')}>
            The laws of the United States of America and New York state shall apply; the UN Convention on Contracts for
            the International Sale of Goods (CISG) shall be excluded.
          </p>
          <p className={style.subtitle}>Exception for European Consumers</p>
          <p className={style.info}>
            However, regardless of the above, if the User qualifies as a European Consumer and has their habitual
            residence in a country where the law provides for a higher consumer protection standard, such higher
            standards shall prevail.
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>22. Venue of jurisdiction</span>
          <p className={classNames(style.info, 'mb')}>
            The exclusive competence to decide on any controversy resulting from or connected to these Terms of Service
            lies with the courts of the place where BATTLE OF PREDICTIONS is based, as displayed in the relevant section
            of this document.
          </p>
          <p className={style.subtitle}>Exception for European Consumers</p>
          <p className={classNames(style.info, 'mb')}>
            The above does not apply to any Users that qualify as European Consumers, nor to Consumers based in
            Switzerland, Norway or Iceland.
          </p>
          <p className={style.subtitle}>US Users</p>
          <p className={classNames(style.info, 'mb')}>
            Each party specifically waives any right to trial by jury in any court in connection with any action or
            litigation. Any claims under these Terms of Service shall proceed individually and no party shall join in a
            class action or other proceeding with or on behalf of others.
          </p>
          <p className={style.subtitle}>UK Users</p>
          <p className={style.info}>
            Consumers based in England may bring legal proceedings in connection with these Terms of Service in the
            English courts. Consumers based in Scotland may bring legal proceedings in connection with these Terms of
            Service in either the Scottish or the English courts. Consumers based in Northern Ireland may bring legal
            proceedings in connection with these Terms of Service in either the Northern Irish or the English courts.
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>23. Dispute resolution</span>
          <p className={style.info}>Amicable dispute resolution</p>
          <p className={classNames(style.info, 'mb')}>
            Users may bring any disputes to us, and we will try to resolve them amicably. While Users' right to take
            legal action shall always remain unaffected, in the event of any controversy regarding the use of our Game
            or other Service, Users are kindly asked to contact us at the contact details provided in this document.
          </p>
          <p className={classNames(style.info, 'mb')}>
            A user may submit the complaint including a brief description and if applicable, the details of the related
            order, purchase, or account, to the BATTLE OF PREDICTIONS’ email address specified in this document. BATTLE
            OF PREDICTIONS will process the complaint without undue delay at possible shortest terms, but in no way no
            longer that 30 days.
          </p>
          <p className={style.subtitle}>Online dispute resolution for Consumers</p>
          <p className={style.info}>
            The European Commission has established an online platform for alternative dispute resolutions that
            facilitates an out-of-court method for solving any dispute related to and stemming from online sale and
            service contracts.
          </p>
          <p className={style.info}>
            As a result, any European Consumer can use such platform for resolving any dispute stemming from contracts
            which have been entered into online. The platform is available at the following link:{' '}
            <a
              className={style.link}
              href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
            >
              https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage
            </a>
          </p>
        </li>
        <li className="mb-large">
          <span className={style.title}>24. Apple Application Store Additional Terms and Conditions</span>
          <p className={classNames(style.info, 'mb')}>
            The following additional terms and conditions apply to You if You are using a Game from the Apple
            Application Store. To the extent the other terms and conditions of this Agreement are less restrictive than,
            or otherwise conflict with, the terms and conditions of the Application Store Terms of Service will control.
          </p>
          <ol>
            <li className="mb">
              <span className={style.subtitle}>24.1. Acknowledgement</span>
              <p className={style.info}>
                BATTLE OF PREDICTIONS and You acknowledge that this Agreement is concluded between BATTLE OF PREDICTIONS
                and You only, and not with Apple, and BATTLE OF PREDICTIONS, not Apple, is solely responsible for Game
                and the content thereof.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>24.2. Scope of License</span>
              <p className={style.info}>
                The license granted to You for Game is limited to a non-transferable license to use Game on an iOS
                Product that You own or control and as permitted by the Usage Rules set forth in the Application Store
                Terms of Service.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>24.3. Maintenance and Support</span>
              <p className={style.info}>
                BATTLE OF PREDICTIONS is solely responsible for providing any maintenance and support services with
                respect to Game, as specified in this Agreement (if any) or as required under applicable law. BATTLE OF
                PREDICTIONS and You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and
                support services with respect to Game.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>24.4. Warranty</span>
              <p className={style.info}>
                BATTLE OF PREDICTIONS is solely responsible for any product warranties, whether expressed or implied by
                law, to the extent not effectively disclaimed. In the event of any failure of Game to conform to any
                applicable warranty, You may notify Apple, and Apple may refund the purchase price for Game to You; and
                to the maximum extent permitted by applicable law, Apple will have no other warranty obligation
                whatsoever with respect to Game, and any other claims, losses, liabilities, damages, costs or expenses
                attributable to any failure to conform to any warranty will be BATTLE OF PREDICTIONS’ sole
                responsibility.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>24.5. Product Claims</span>
              <p className={style.info}>
                BATTLE OF PREDICTIONS and You acknowledge that BATTLE OF PREDICTIONS, not Apple, is responsible for
                addressing any claims of You or any third party relating to Game or Your possession and/or use of Game,
                including, but not limited to: (i) product liability claims; (ii) any claim that Game fails to conform
                to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or
                similar legislation. This Agreement does not limit BATTLE OF PREDICTIONS’ liability to You beyond what
                is permitted by applicable law.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>24.6. Intellectual Property Rights</span>
              <p className={style.info}>
                BATTLE OF PREDICTIONS and You acknowledge that, in the event of any third-party claim that Game or Your
                possession and use of Game infringes the third party’s intellectual property rights, BATTLE OF
                PREDICTIONS, not Apple, will be solely responsible for the investigation, defense, settlement and
                discharge of any such intellectual property infringement claim.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>24.7. Legal Compliance</span>
              <p className={style.info}>
                You represent and warrant that (i) You are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a «terrorist supporting» country; and (ii) You are not listed on any U.S. Government list of prohibited or restricted parties.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>24.8. Developer Name and Address</span>
              <p className={style.info}>
                BATTLE OF PREDICTIONS’ contact information for any end-user questions, complaints or claims with respect to Game:
                BATTLE OF PREDICTIONS TECHNOLOGY INC, a company under laws of the United States of America, with registered address at: 2546 e 17th street, 2nd floor, BROOKLYN, NY 11235
                Email: support@bofp.co
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>24.9. Third-Party Terms of Agreement</span>
              <p className={style.info}>
                You must comply with applicable third-party terms of agreement when using a Game.
              </p>
            </li>
            <li className="mb">
              <span className={style.subtitle}>24.10. Third-Party Beneficiary</span>
              <p className={style.info}>
                BATTLE OF PREDICTIONS and You acknowledge and agree that Apple, and Apple’s subsidiaries, are third-party beneficiaries of this Agreement, and that, upon Your acceptance of the terms and conditions of this Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this Agreement against You as a third-party beneficiary thereof.
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
}

export default TermsList;
