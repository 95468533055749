import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import styles from './termsAbout.module.scss';

function TermsAboutRU() {
  return (
    <div>
      <p className={styles.descr}>
        Условия настоящего соглашения ("Условия предоставления услуг" или "Соглашение") регулируют отношения между Вами и компанией BATTLE OF PREDICTIONS TECHNOLOGY INC, действующей в соответствии с законодательством Соединенных Штатов Америки и зарегистрированной по адресу: 2546 e 17th street, 2nd floor, BROOKLYN, NY 11235 ("BATTLE OF PREDICTIONS" или "Мы" или "Нас", "Наши" и тд), в отношении использования Вами игр, веб-сайтов и сопутствующих услуг BATTLE OF PREDICTIONS ("далее - Услуги"), включая всю информацию, тексты, произведения искусства, изображения, логотипы, названия продуктов, графику, программное обеспечение, услуги, сервисы и компиляцию вышеперечисленного, предоставляемые для Вашего использования.

      </p>
      <p className={classNames(styles.descr, 'mb')}>
        Использование Услуг также регулируется Политикой конфиденциальности BATTLE OF PREDICTIONS, которая включена в настоящий документ посредством ссылки. Вы должны согласиться с настоящими Условиями предоставления услуг и Политикой конфиденциальности до получения доступа к Услугам и их использования.

      </p>
      <p className={classNames(styles.descr, 'mb')}>
        Последнюю обновленную версию Условий предоставления услуг и Политики конфиденциальности можно в любое время найти на нашем сайте
        <Link to="/" className={styles.link}>
          Site→
        </Link>
        .
      </p>
      <p className={classNames(styles.descr, 'mb')}>
        BATTLE OF PREDICTIONS является разработчиком и издателем игр и мобильных игровых приложений (далее "Игры"). Страница загрузки/покупки наших Игр может содержать некоторые дополнительные положения, условия и требования, которые являются частью Условий предоставления услуг. В случае загрузки/приобретения Игр через магазин приложений, такой как Google Play Store, Apple App Store и т.д. (далее - "магазин приложений"), пожалуйста, ознакомьтесь с условиями и положениями владельца данного магазина приложений, которые могут предусматривать определенные дополнительные требования, применимые к загрузке Игр через данный магазин приложений, их установке и использованию.

      </p>
      <p className={classNames(styles.descr, 'mb')}>
        Мы можем проводить розыгрыши, конкурсы и аналогичные акции (далее - "Акции") при предоставлении Услуг. Вам следует внимательно изучить правила (например, "Официальные правила") каждой Акции, в которой Вы участвуете, пользуясь нашими Услугами, поскольку они могут содержать дополнительную важную информацию о наших правах и правах собственности на материалы, документы, информацию или другие вложения, которые Вы предоставляете в рамках Акций и в результате Вашего участия в таких Акциях. В той мере, в какой положения и условия таких Официальных правил противоречат настоящим Условиям предоставления услуг, преимущественную силу имеют положения и условия таких Официальных правил.

      </p>
      <p className={classNames(styles.descr, 'mb')}>
        Вы подтверждаете, что Вам 16 лет или более. Если Вы старше 16 лет, но моложе 18 лет, Вы можете загружать наши Игры и играть в них на своем устройстве только в том случае, если Ваш родитель (родители) или законный опекун ознакомился (ознакомились) с настоящим Соглашением и разрешил Вам загружать и играть в наши Игры в соответствии с настоящим Соглашением. BATTLE OF PREDICTIONS может в любое время потребовать от родителей или опекунов надлежащего подтверждения Вашей личности, возраста и согласия.

      </p>
      <p className={classNames(styles.descr, 'mb')}>
        Вы подтверждаете, что получаете доступ к нашим Играм как частное лицо (потребитель). Услуги, предлагаемые BATTLE OF PREDICTIONS, предназначены исключительно для целей развлечения. Коммерческое использование наших Игр не допускается.

      </p>
      <p className={classNames(styles.descr, 'mb')}>
        УСЛУГИ ПРЕДОСТАВЛЯЮТСЯ ИСКЛЮЧИТЕЛЬНО В РАЗВЛЕКАТЕЛЬНЫХ ЦЕЛЯХ. УСЛУГИ НЕ ПРЕДПОЛАГАЮТ АЗАРТНЫЕ ИГРЫ НА РЕАЛЬНЫЕ ДЕНЬГИ ИЛИ ВОЗМОЖНОСТЬ ВЫИГРАТЬ РЕАЛЬНЫЕ ДЕНЬГИ ИЛИ ПРИЗЫ В РЕАЛЬНОМ МИРЕ. В ИГРАХ, ПРЕДЛАГАЕМЫХ BATTLE OF PREDICTIONS, НЕЛЬЗЯ ВЫИГРАТЬ РЕАЛЬНЫЕ ДЕНЬГИ ИЛИ ЧТО-ЛИБО ЦЕННОЕ, И ДЛЯ ИГРЫ НЕ ТРЕБУЮТСЯ РЕАЛЬНЫЕ ДЕНЬГИ.

      </p>
      <p className={classNames(styles.descr, 'mb-large')}>
        Используя наши Услуги, загружая любое программное обеспечение или просматривая веб-сайт, а также загружая любую из Игр BATTLE OF PREDICTIONS из магазинов приложений, Вы принимаете настоящие Условия предоставления услуг. Если Вы не согласны с Условиями предоставления услуг и/или Политикой конфиденциальности, Вы не имеете права использовать или иным образом получать доступ к Услугам. Использование Услуг является неправомочным там, где это законодательно запрещено.

      </p>
    </div>
  );
}

export default TermsAboutRU;
