import React, {useContext} from 'react';
import {Logo} from '../../components/shared/Logo';
import {Title} from '../../components/shared/Title';
import {PrivacyList} from '../../components/PrivacyList';
import Header from "../Faq/cmp/Header";
import {I18nContext} from "../../store/I18nStore";
import PrivacyListRU from "../../components/PrivacyList/PrivacyListRU";

function Privacy() {
  const {lang} = useContext(I18nContext);
  return (
    <>
      <Header/>
      <div style={{textAlign: 'left', padding: '15px'}}>
        <Logo/>
        {lang === 'ru' ?  <Title title="Политика конфиденциальности" updated="Ноябрь 20, 2023"/>:<Title title="Privacy policy" updated="November 20, 2023"/>}
        {lang === 'ru' ? <PrivacyListRU/> : <PrivacyList/>}
      </div>
    </>
  );
}

export default Privacy;
