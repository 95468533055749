import { useNavigate, useLocation } from 'react-router-dom';

type Params = Record<string, string>;

const useNavigateWithCurrentParams = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateWithCurrentParams = (pathOrDelta: string | number, additionalParams: Params = {}) => {
    if (typeof pathOrDelta === 'number') {
      navigate(pathOrDelta);
    } else {
      const searchParams = new URLSearchParams(location.search);

      Object.keys(additionalParams).forEach(key => {
        searchParams.set(key, additionalParams[key]);
      });

      navigate(`${pathOrDelta}?${searchParams.toString()}`);
    }
  };

  return navigateWithCurrentParams;
};

export default useNavigateWithCurrentParams;
