import { IProvider } from '../types';

export const ROOT = {
  euro2024Prediction: '/euro2024/prediction/:id/:setStatus',
  euro2024Leaderboard: '/euro2024/leaderboard',
  euro2024: '/euro2024',
  home: '/',
  terms: '/terms',
  privacy: '/privacy',
  providers: '/providers',
  faq: 'faq/*',
  buff_tutorial: 'faq/buff_tutorial/*',
  buff_shattering: 'faq/buff_shattering/*',
  inactivity: 'faq/inactivity/*',
  login: 'faq/login/*',
  buff_getting: 'faq/buff_getting/*',
  ranking_atp: 'faq/ranking_atp/*',
  ranking_wta: 'faq/ranking_wta/*',
  tennis_doubles: 'faq/tennis_doubles/*',
  tournaments_exit: 'faq/tournaments_exit/*',
  fanclubs_exit: 'faq/fanclubs_exit/*',
  release_notes: 'faq/release_notes/*',
};

export const PROVIDERS: IProvider[] = [
  {
    title: 'Google LLC',
    subtitle: '(Firebase /BigQuery)',
    link: 'https://firebase.google.com/support/privacy/',
    type: 'Analytics provider, tracking event; A/B testing',
  },
  {
    title: 'Google LLC',
    subtitle: '(Google play market)',
    link: 'https://policies.google.com/privacy?hl=en&gl=by',
    type: 'Analytics provider',
  },
  {
    title: 'Apple Inc.',
    subtitle: '(Apple Itunes Connect)',
    link: 'https://www.apple.com/legal/privacy/en-ww/',
    type: 'Analytics provider',
  },
  {
    title: 'Google LLC',
    subtitle: '(Google Cloud Platform)',
    link: 'https://cloud.google.com/security/privacy/',
    type: 'Cloud service provider',
  },
  {
    title: 'Facebook, Inc.',
    subtitle: '(FB Analytics)',
    link: 'https://www.facebook.com/about/privacy/',
    type: 'Analytics provider, Tracking SDK; Sharing',
  },
];

export const PROVIDERS_RU: IProvider[] = [
  {
    title: 'Google LLC',
    subtitle: '(Firebase /BigQuery)',
    link: 'https://firebase.google.com/support/privacy/',
    type: 'Поставщик аналитики, отслеживание событий; A/B тестированиеg',
  },
  {
    title: 'Google LLC',
    subtitle: '(Google play market)',
    link: 'https://policies.google.com/privacy?hl=en&gl=by',
    type: 'Поставщик аналитики',
  },
  {
    title: 'Apple Inc.',
    subtitle: '(Apple Itunes Connect)',
    link: 'https://www.apple.com/legal/privacy/en-ww/',
    type: 'Поставщик аналитики',
  },
  {
    title: 'Google LLC',
    subtitle: '(Google Cloud Platform)',
    link: 'https://cloud.google.com/security/privacy/',
    type: 'Поставщик облачных сервисов',
  },
  {
    title: 'Facebook, Inc.',
    subtitle: '(FB Analytics)',
    link: 'https://www.facebook.com/about/privacy/',
    type: 'Поставщик аналитики, отслеживание SDK; обмен данными ',
  },
];
