// Skeleton.tsx
import React from 'react';

const shimmerKeyframes = `
  @keyframes shimmer {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
`;

interface SkeletonProps {
  width?: string;
  height?: string;
  style?: React.CSSProperties;
  image?: string,
}

const Skeleton: React.FC<SkeletonProps> = ({width, height, style, image}) => {
  const skeletonStyle: React.CSSProperties = {
    display: 'inline-block',
    width: width || '100%',
    height: height || '100%',
    background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
    backgroundSize: '200% 100%',
    animation: 'shimmer 1.5s infinite',
    borderRadius: '12px',
    ...style,
  };

  return (
    <div>
      <style>{shimmerKeyframes}</style>
      {image ? <img style={skeletonStyle} src={image}/> : <div style={skeletonStyle}></div>}
    </div>
  );
};

export default Skeleton;
