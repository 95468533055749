import React, {useContext} from 'react';
import { HashLink } from 'react-router-hash-link';
import Basketball_leaderboard_points_l3_buff from '../../assets/images/buffs/Basketball_leaderboard_points_l3_buff.png';
import Bonus_coins_earned_l1_buff from '../../assets/images/buffs/Bonus_coins_earned_l1_buff.png';
import Bonus_coins_earned_l2_buff from '../../assets/images/buffs/Bonus_coins_earned_l2_buff.png';
import Bonus_coins_earned_l3_buff from '../../assets/images/buffs/Bonus_coins_earned_l3_buff.png';
import Cancel_red_card from '../../assets/images/buffs/Cancel_red_card.png';
import Change_score from '../../assets/images/buffs/Change_score.png';
import Change_match_buff from '../../assets/images/buffs/Change_match_buff.png';
import Forward from '../../assets/images/buffs/Forward.png';
import Freeze_match_buff from '../../assets/images/buffs/Freeze_match_buff.png';
import Goalkeeper from '../../assets/images/buffs/Goalkeeper.png';
import Heal from '../../assets/images/buffs/Heal.png';
import Hockey_leaderboard_points_l3_buff from '../../assets/images/buffs/Hockey_leaderboard_points_l3_buff.png';
import Injure from '../../assets/images/buffs/Injure.png';
import buffs_annihilation from '../../assets/images/buffs/buffs_annihilation.png';
import buffs_destroy from '../../assets/images/buffs/buffs_destroy.png';
import buffs_offside from '../../assets/images/buffs/buffs_offside.png';
import buffs_shield_base from '../../assets/images/buffs/buffs_shield_base.png';
import buffs_shield_golden from '../../assets/images/buffs/buffs_shield_golden.png';
import buffs_shield_golden_double from '../../assets/images/buffs/buffs_shield_golden_double.png';
import buffs_unsportsmanlike from '../../assets/images/buffs/buffs_unsportsmanlike.png';
import buffs_var from '../../assets/images/buffs/buffs_var.png';
import Kicker from '../../assets/images/buffs/Kicker.png';
import Soccer_leaderboard_points_l1_buff from '../../assets/images/buffs/Soccer_leaderboard_points_l1_buff.png';
import Soccer_leaderboard_points_l3_buff from '../../assets/images/buffs/Soccer_leaderboard_points_l3_buff.png';
import Tennis_leaderboard_points_l3_buff from '../../assets/images/buffs/Tennis_leaderboard_points_l3_buff.png';
import Time_machine from '../../assets/images/buffs/Time_machine.png';
import Total_fix from '../../assets/images/buffs/Total_fix.png';
import { IBImgProps, IBLinkProps, IBrProps, IBSectionProps, IBTextProps } from './types';
import { I18nContext } from '../../store/I18nStore';
import Header from "./cmp/Header";

function FaqBuffTutorial() {
  const { i18n, getT, scheme } = useContext(I18nContext);


  function BText(props: IBTextProps) {
    return (
      <div
        style={{
          fontFamily: 'Inter',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '16px',
          letterSpacing: '-0.24px',
        }}
        dangerouslySetInnerHTML={{ __html: getT(props.t).replaceAll('\n', '<br>') }}
      />
    );
  }

  function BLink(props: IBLinkProps) {
    const style: React.CSSProperties = {
      color: scheme === 'dark' ? '#866ADC' : '#6E4CD9',
      textDecoration: 'none',
      fontFamily: 'Inter',
      letterSpacing: '-0.24px',
    };

    if (props.t.indexOf('section') > -1) {
      style.fontSize = '16px';
      style.fontWeight = '600';
      style.lineHeight = '20px';
      style.letterSpacing = '-0.24px';
      style.paddingLeft = '0px';
    } else {
      style.fontWeight = '400';
      style.fontSize = '14px';
      style.lineHeight = '20px';
      style.paddingLeft = '5px';
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {props.img && <img style={{ width: '24px' }} src={props.img} alt={props.img} />}
        <HashLink style={style} to={'#' + props.t}>
          {getT(props.t)}
        </HashLink>
      </div>
    );
  }

  function BSection(props: IBSectionProps) {
    const style = {
      fontFamily: 'Inter',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.24px',
    };

    if (props.big) {
      style.fontSize = '20px';
    }

    return (
      <div style={style} id={props.t}>
        {getT(props.t)}
      </div>
    );
  }

  function BImg(props: IBImgProps) {
    const style: React.CSSProperties = {
      width: '96px',
    };

    if (props.stacked) {
      style.marginLeft = '-40px';
    }

    return <img style={style} src={props.img} alt={props.img} />;
  }

  function Br(props: IBrProps) {
    const arr = [];

    for (let i = 0; i < (props.length || 1); i++) {
      arr.push(i);
    }

    return (
      <>
        {arr.map((_, i) => (
          <br key={i} />
        ))}
      </>
    );
  }
  if (i18n === null) {
    return null;
  }

  return (
    <>
      <Header />
      <div style={{ textAlign: 'left', padding: '15px' }}>
        <BSection t={'page_title_buffs'} big={true} />

        <Br />
        <BText t={'page_description_buffs'} />

        <Br length={1} />
        {/*Навигация (заголовки секций и сами вложенности - все должно быть переходом в основной блок)*/}
        <BLink t={'section_name_match'} />
        <BLink t={'name_change_score_buff_token'} img={Change_score} />
        <BLink t={'name_prediction_after_start_buff_token'} img={Time_machine} />
        <BLink t={'name_penalty_ignore_total_buff_token'} img={Total_fix} />
        <BLink t={'name_additional_goal_buff_token'} img={Forward} />
        <BLink t={'name_goalkeeper_buff_token'} img={Goalkeeper} />
        <BLink t={'name_penalty_taker_buff_token'} img={Kicker} />
        <BLink t={'name_injury_buff_token'} img={Injure} />
        <BLink t={'name_treatment_buff_token'} img={Heal} />
        <BLink t={'name_red_card_cancel_buff_token'} img={Cancel_red_card} />
        <BLink t={'name_opponent_slot_block_buff_token'} img={buffs_shield_base} />
        <BLink t={'name_opponent_slot_block_2_buff_token'} img={buffs_shield_golden} />
        <BLink t={'name_all_slots_block_buff_token'} img={buffs_shield_golden_double} />
        <BLink t={'name_destroy_buff_token'} img={buffs_destroy} />
        <BLink t={'name_destroy_all_buff_token'} img={buffs_annihilation} />
        <BLink t={'name_red_cards_buff_token'} img={buffs_unsportsmanlike} />
        <BLink t={'name_var_buff_token'} img={buffs_var} />
        <BLink t={'name_no_goals_buff_token'} img={buffs_offside} />

        <Br />
        <BLink t={'section_name_before_match'} />
        <BLink t={'name_change_match_buff'} img={Change_match_buff} />
        <BLink t={'name_freeze_match_buff'} img={Freeze_match_buff} />
        <Br />
        <BLink t={'section_name_personal'} />
        <BLink t={'name_bonus_coins_earned_buff'} img={Bonus_coins_earned_l1_buff} />
        <BLink t={'name_leaderboard_points_buff'} img={Soccer_leaderboard_points_l1_buff} />

        <Br length={3} />
        <BSection t={'section_name_match'} big={true} />
        <BText t={'section_description_match'} />

        {/*Основная часть*/}
        {/*Секция*/}
        {/*Бафы*/}
        {/*<картинка change score>*/}
        <Br length={1} />
        <BImg img={Change_score} />
        <BSection t={'name_change_score_buff_token'} />
        <BText t={'description_change_score_buff_token'} />

        {/*<картинка time-machine>*/}
        <Br length={1} />
        <BImg img={Time_machine} />
        <BSection t={'name_prediction_after_start_buff_token'} />
        <BText t={'description_prediction_after_start_buff_token'} />

        {/*<картинка  total>*/}
        <Br length={1} />
        <BImg img={Total_fix} />
        <BSection t={'name_penalty_ignore_total_buff_token'} />
        <BText t={'description_penalty_ignore_total_buff_token'} />

        {/*<картинка forward>*/}
        <Br length={1} />
        <BImg img={Forward} />
        <BSection t={'name_additional_goal_buff_token'} />
        <BText t={'description_additional_goal_buff_token'} />

        {/*<картинка goalkeeper>*/}
        <Br length={1} />
        <BImg img={Goalkeeper} />
        <BSection t={'name_goalkeeper_buff_token'} />
        <BText t={'description_goalkeeper_buff_token'} />

        {/*<картинка kicker>*/}
        <Br length={1} />
        <BImg img={Kicker} />
        <BSection t={'name_penalty_taker_buff_token'} />
        <BText t={'description_penalty_taker_buff_token'} />

        {/*<картинка injure>*/}
        <Br length={1} />
        <BImg img={Injure} />
        <BSection t={'name_injury_buff_token'} />
        <BText t={'description_injury_buff_token'} />

        {/*<картинка heal>*/}
        <Br length={1} />
        <BImg img={Heal} />
        <BSection t={'name_treatment_buff_token'} />
        <BText t={'description_treatment_buff_token'} />

        {/*<картинка cancel red card>*/}
        <Br length={1} />
        <BImg img={Cancel_red_card} />
        <BSection t={'name_red_card_cancel_buff_token'} />
        <BText t={'description_red_card_cancel_buff_token'} />

        <Br length={1} />
        <BImg img={buffs_shield_base} />
        <BSection t={'name_opponent_slot_block_buff_token'} />
        <BText t={'description_opponent_slot_block_buff_token'} />

        <Br length={1}/>
        <BImg img={buffs_shield_golden}/>
        <BSection t={'name_opponent_slot_block_2_buff_token'}/>
        <BText t={'description_opponent_slot_block_2_buff_token'}/>

        <Br length={1}/>
        <BImg img={buffs_shield_golden_double}/>
        <BSection t={'name_all_slots_block_buff_token'}/>
        <BText t={'description_all_slots_block_buff_token'}/>

        <Br length={1}/>
        <BImg img={buffs_destroy}/>
        <BSection t={'name_destroy_buff_token'}/>
        <BText t={'description_destroy_buff_token'}/>

        <Br length={1}/>
        <BImg img={buffs_annihilation}/>
        <BSection t={'name_destroy_all_buff_token'}/>
        <BText t={'description_destroy_all_buff_token'}/>

        <Br length={1}/>
        <BImg img={buffs_unsportsmanlike}/>
        <BSection t={'name_red_cards_buff_token'}/>
        <BText t={'description_red_cards_buff_token'}/>

        <Br length={1}/>
        <BImg img={buffs_var}/>
        <BSection t={'name_var_buff_token'}/>
        <BText t={'description_var_buff_token'}/>

        <Br length={1}/>
        <BImg img={buffs_offside}/>
        <BSection t={'name_no_goals_buff_token'}/>
        <BText t={'description_no_goals_buff_token'}/>


        {/*Секция*/}
        <Br length={3} />
        <BSection t={'section_name_before_match'} big={true} />
        <BText t={'section_description_before_match'} />

        {/*Бафы*/}
        {/*<картинка freeze_match_buff>*/}
        <Br length={1} />
        <BImg img={Freeze_match_buff} />
        <BSection t={'name_freeze_match_buff'} />
        <BText t={'description_freeze_match_buff'} />

        {/*<картинка change_match_buff>*/}
        <Br length={1} />
        <BImg img={Change_match_buff} />
        <BSection t={'name_change_match_buff'} />
        <BText t={'description_change_match_buff'} />

        {/*Секция*/}
        <Br length={3} />
        <BSection t={'section_name_personal'} big={true} />
        <BText t={'section_description_personal'} />

        {/*Бафы*/}
        {/*<картинкИ bonus_coins_earned_l1_buff + bonus_coins_earned_l2_buff + bonus_coins_earned_l3_buff>*/}
        <Br length={1} />
        <BImg img={Bonus_coins_earned_l1_buff} />
        <BImg img={Bonus_coins_earned_l2_buff} stacked={true} />
        <BImg img={Bonus_coins_earned_l3_buff} stacked={true} />
        <BSection t={'name_bonus_coins_earned_buff'} />
        <BText t={'description_bonus_coins_earned_buff'} />

        {/*<картинкИ soccer_leaderboard_points_l3_buff +
        hockey_leaderboard_points_l3_buff + basketball_leaderboard_points_l3_buff +
        tennis_leaderboard_points_l3_buff>*/}
        <Br length={1} />
        <BImg img={Soccer_leaderboard_points_l3_buff} />
        <BImg img={Hockey_leaderboard_points_l3_buff} stacked={true} />
        <BImg img={Basketball_leaderboard_points_l3_buff} stacked={true} />
        <BImg img={Tennis_leaderboard_points_l3_buff} stacked={true} />
        <BSection t={'name_leaderboard_points_buff'} />
        <BText t={'description_leaderboard_points_buff'} />
      </div>
    </>
  );
}

export default FaqBuffTutorial;
