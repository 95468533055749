import React, {createContext, useState, useEffect} from 'react';
import {useSearchParams} from "react-router-dom";

export interface FaqDickResp {
  language: string;
  data: object;
}


type I18n = {
  [key: string]: {
    [key: string]: string;
  };
};

interface I18nContextType {
  i18n: I18n | null;
  setI18n: React.Dispatch<React.SetStateAction<I18n | null>>;
  getT: (key: string, plural?: number | string) => string;
  setLang: (lang: string) => void;
  lang: string;
  setScheme: (scheme: string) => void;
  scheme: string;
  accessToken: string;
}

interface I18nProviderProps {
  children: React.ReactNode;
}

export const I18nContext = createContext<I18nContextType>({
  i18n: null,
  setI18n: () => {
  },
  getT: () => '',
  setLang: () => '',
  lang: 'en',
  setScheme: () => '',
  scheme: 'light',
  accessToken: '',
});

function getInitLocale() {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has('locale')) {
    return urlParams.get('locale') || 'en';
  } else {
    return window.location.href.indexOf('/ru') > 10 ? 'ru' : 'en'
  }
}

function getAccessToken() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('access_token') || '';
}

export const I18nProvider: React.FC<I18nProviderProps> = ({children}) => {
  const [searchParams] = useSearchParams();
  const [i18n, setI18n] = useState<I18n | null>(null);
  const [scheme, setScheme] = useState<string>(searchParams.get('theme')?.toLowerCase() === 'dark' ? 'dark' : 'light');
  const [lang, setLang] = useState<string>(getInitLocale());
  const [accessToken] = useState<string>(getAccessToken());

  useEffect(() => {
    fetch('https://api-prod.bop.rest/v2/service/lokalise/FAQ')
      .then((response) => response.json())
      .then((data) => {
        setI18n({
          en: data.filter((item: FaqDickResp) => item.language === 'en')[0]?.data || {},
          ru: data.filter((item: FaqDickResp) => item.language === 'ru')[0]?.data || {},
          uk: data.filter((item: FaqDickResp) => item.language === 'uk')[0]?.data || {},
        });
      });
  }, []);
  const getT = (key: string, plural?: number | string): string => {
    if (i18n === null) return key;
    const dic = i18n[lang] ? i18n[lang] : i18n['en'];

    let newKey = key;
    if (plural !== null && plural !== undefined) {
      plural = plural.toString();
      if (lang === 'en') {
        if (plural.endsWith('1')) {
          newKey = key + '_one'
        } else {
          newKey = key + '_other'
        }
      } else {
        if (plural == '11' || plural == '12' || plural == '13' || plural == '14' || plural.endsWith(' 11') || plural.endsWith(' 12') || plural.endsWith(' 13') || plural.endsWith(' 14')) {
          newKey = key + '_other'
        } else if (plural.endsWith('1')) {
          newKey = key + '_one'
        } else if (plural.endsWith('2') || plural.endsWith('3') || plural.endsWith('4')) {
          newKey = key + '_few'
        } else {
          newKey = key + '_other'
        }
        if (!dic[newKey]) {
          newKey = key + '_other';
        }
      }
    }

    const text = dic[newKey] || i18n['en'][newKey] || newKey;
    return text;
  };
  return (
    <I18nContext.Provider value={{i18n, setI18n, getT, setLang, lang, scheme, setScheme, accessToken}}>
      {children}
    </I18nContext.Provider>
  );
};
