import {useState, useEffect, useRef} from 'react';

interface OnScreenInfo {
  isIntersecting: boolean;
  position: 'above' | 'below' | 'inView';
}

function useOnScreen<T extends Element>(ref: React.RefObject<T>, rootMargin: string = "0px", active = false): OnScreenInfo {
  const [info, setInfo] = useState<OnScreenInfo>({isIntersecting: false, position: 'above'});

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (active) {
          const isIntersecting = entry.isIntersecting;
          let position: 'above' | 'below' | 'inView' = 'inView';

          if (!isIntersecting) {
            if (entry.boundingClientRect.top < entry.rootBounds?.top!) {
              position = 'above';
            } else {
              position = 'below';
            }
          }
          setInfo({isIntersecting, position});
        }
      },
      {rootMargin}
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, rootMargin]);

  return info;
}

export default useOnScreen;
